export interface AddressFormData {
  id?: string;
  street: string;
  number: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

export interface ContactFormData {
  id?: string;
  name: string;
  email: string;
  phoneNumber: string;
}

export interface CompanyFormData {
  id?: string;
  name: string;
  cnpj: string;
  observation?: string;
  addresses: AddressFormData[];
  contacts: ContactFormData[];
}

export const processStatus = [
  { label: "DOCUMENTAÇÃO", value: "DOCUMENTAÇÃO" },
  { label: "MERCADORIA EM PRODUÇÃO", value: "MERCADORIA EM PRODUÇÃO" },
  { label: "BOOKING", value: "BOOKING" },
  { label: "LI", value: "LI" },
  { label: "DI", value: "DI" },
  { label: "EMBARQUE", value: "EMBARQUE" },
  { label: "EM TRÂNSITO", value: "EM TRÂNSITO" },
  {
    label: "CHEGOU NO PORTO DE DESTINO",
    value: "CHEGOU NO PORTO DE DESTINO",
  },
  {
    label: "CHEGOU NO AEROPORTO DE DESTINO",
    value: "CHEGOU NO AEROPORTO DE DESTINO",
  },
  { label: "MANIFESTADO", value: "MANIFESTADO" },
  { label: "ARMAZENADO", value: "ARMAZENADO" },
  { label: "DI REGISTRADA", value: "DI REGISTRADA" },
  { label: "DESEMBARAÇADO", value: "DESEMBARAÇADO" },
  { label: "EM CARREGAMENTO", value: "EM CARREGAMENTO" },
  { label: "ENTREGUE", value: "ENTREGUE" },
  {
    label: "AGUARDANDO FECHAMENTO",
    value: "AGUARDANDO FECHAMENTO",
  },
  {
    label: "ENCERRADO",
    value: "ENCERRADO",
  },
];
