import {
  Box,
  Flex,
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Button,
  ModalHeader,
  ModalFooter,
  Heading,
} from "@chakra-ui/react";
import Card from "../../Home/Components/Card";
import { companyOptions, useCompany } from "../../../contexts/CompanyContext";
import { useNavigate } from "react-router-dom";

const CompanySelectModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { setCurrentCompany } = useCompany();
  const navigate = useNavigate();
  const redirect = new URLSearchParams(window.location.search).get("redirect");
  const processId = new URLSearchParams(window.location.search).get(
    "processId"
  );

  const handleCardClick = (companyKey: string) => {
    setCurrentCompany(companyOptions.find((c) => c.key === companyKey)?.value);
    navigate(
      redirect
        ? "/purchaseOrders"
        : processId
        ? `/tracking?id=${processId}`
        : "/"
    );
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="6xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="space-between">
            <Heading fontWeight="normal" textAlign="center" w="100%">
              Selecione a filial
            </Heading>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex justifyContent="center" p="32px" mb="32px">
            <Flex
              gap="24px"
              flexDir={{ base: "column", md: "row" }}
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              overflowX="auto"
            >
              {companyOptions.map((c) => (
                <Box
                  borderRadius="6px"
                  borderWidth="1px"
                  borderColor="lightgray"
                >
                  <Card
                    button
                    option={{
                      title: c.label,
                      link: () => handleCardClick(c.key),
                      position: "center",
                    }}
                  />
                </Box>
              ))}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default CompanySelectModal;
