import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";

export const companyOptions = [
  { key: "CVFLogistics", label: "CVF Logística", value: 1 },
  { key: "CVFDistributions", label: "CVF Distribuidora", value: 2 },
  { key: "CVFRondonia", label: "CVF Rondônia", value: 3 },
  { key: "WTrading", label: "W Trading", value: 4 },
  { key: "General", label: "Todas", value: 5 },
];

export interface CompanyContextProps {
  currentCompany: number | undefined;
  setCurrentCompany: (val: number | undefined) => void;
}

const CompanyContext = createContext<CompanyContextProps>({
  currentCompany: undefined,
  setCurrentCompany: () => {},
});

export const useCompany = () => {
  const context = useContext(CompanyContext);
  if (!context) {
    throw new Error("useCompany must be used within a CompanyProvider");
  }
  return context;
};

export const CompanyProvider = ({ children }: { children: ReactNode }) => {
  const [currentCompany, setCurrentCompany] = useState<number>();

  const handleChange = (val: number | undefined) => {
    setCurrentCompany(val);
    if (!!val) {
      localStorage.setItem("appCompany", val.toString());
    } else {
      localStorage.removeItem("appCompany");
    }
  };

  useEffect(() => {
    const savedCompany = localStorage.getItem("appCompany");
    if (savedCompany != null) {
      setCurrentCompany(parseFloat(savedCompany));
      document.documentElement.lang = savedCompany;
    }
  }, []);
  const value = {
    currentCompany,
    setCurrentCompany: handleChange,
  };

  return (
    <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
  );
};
