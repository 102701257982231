import {
  FormControl,
  FormLabel,
  Select,
  SelectProps,
  Text,
} from "@chakra-ui/react";
import { useField } from "@unform/core";
import { useEffect, useRef } from "react";
export interface SelectOption {
  value: any;
  label: any;
}

export interface CustomSelectProps extends SelectProps {
  name: string;
  options: SelectOption[];
  value?: string;
  label?: string;
  isLoading?: boolean;
  isDynamicField?: boolean;
}

const SelectField = ({
  name,
  label,
  value,
  isLoading,
  options,
  onChange,
  isDynamicField = false,
  ...rest
}: CustomSelectProps) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  const {
    fieldName,
    defaultValue = rest.defaultValue,
    registerField,
    error,
  } = useField(name);
  useEffect(() => {
    registerField({
      ref: selectRef,
      name: fieldName,
      getValue: (ref) => {
        return ref.current?.value;
      },
      setValue: (ref, newValue) => {
        ref.current.value = newValue;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <FormControl>
      {label && (
        <FormLabel
          color={isDynamicField ? undefined : "black"}
          fontSize={isDynamicField ? undefined : "20px"}
          fontWeight={isDynamicField ? undefined : "600"}
          htmlFor={fieldName}
        >
          {label}
        </FormLabel>
      )}

      <Select
        id={fieldName}
        name={fieldName}
        ref={selectRef}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={
          rest.isDisabled ? "Sem dados" : isLoading ? "Buscando dados..." : ""
        }
        isReadOnly={rest.isReadOnly}
        isDisabled={isLoading ?? rest.isDisabled}
        isInvalid={!!error}
        {...rest}
      >
        {options.map((op) => (
          <option key={`select-${op.label}`} value={op.value}>
            {op.label}
          </option>
        ))}
      </Select>

      {error && (
        <Text fontSize="xs" color="red.300">
          {error}
        </Text>
      )}
    </FormControl>
  );
};

export default SelectField;
