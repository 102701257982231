import { SetStateAction, Dispatch } from "react";
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import NestedTableRow from "./NestedTableRow";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

const Table = ({
  columns,
  emptyMessage = "Nenhum dado encontrado",
  nestedColumns,
  page,
  financial = false,
  onSort,
  sortField,
  setSortField,
  sortDirection,
  setSortDirection,
}: {
  columns: {
    label: string;
    index?: string;
    data: any;
    width?: string[] | string | number;
    sortable?: boolean;
  }[];
  nestedColumns?: {
    label: string;
    index?: string;
    data: any;
    width?: string[] | string | number;
  }[];
  sortField: string | undefined;
  setSortField: Dispatch<SetStateAction<string | undefined>>;
  sortDirection: "asc" | "desc";
  setSortDirection: Dispatch<SetStateAction<"asc" | "desc">>;
  page: any[];
  financial?: boolean;
  emptyMessage: string;
  onSort: (field: string, direction: "asc" | "desc") => void;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleHeaderClick = (field: string) => {
    if (sortField === field) {
      const newDirection = sortDirection === "asc" ? "desc" : "asc";

      onSort(sortDirection === "desc" ? "" : field, newDirection);
      setSortDirection(newDirection);
    } else {
      setSortField(field);
      setSortDirection("asc");
      onSort(field, "asc");
    }
  };

  const renderSortIcon = (field: string) => {
    if (sortField !== field) return <FaSort />;
    return sortDirection === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <ChakraTable variant="simple" fontFamily="Inter">
      <Thead>
        <Tr>
          {columns.map((column, idx) => (
            <Th
              onClick={() =>
                column.sortable ? handleHeaderClick(column.index ?? "") : null
              }
              cursor={column.sortable ? "pointer" : "default"}
              position="sticky"
              backgroundColor="white"
              top="0"
              right={column.label.length === 0 ? 0 : undefined}
              zIndex={column.label.length === 0 ? "10" : "1"}
              textTransform="none"
              key={idx}
              width={column.width}
              _hover={{ bg: column.sortable ? "gray.50" : "inherit" }}
            >
              <Flex alignItems="center" gap="8px">
                {column.label}
                {column.sortable && (
                  <Box>{renderSortIcon(column.index ?? "")}</Box>
                )}
              </Flex>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {page.length == 0 ? (
          <Text margin="32px">{emptyMessage}</Text>
        ) : (
          page.map((row) => (
            <NestedTableRow
              {...{ financial, row, columns, nestedColumns, isMobile }}
            />
          ))
        )}
      </Tbody>
    </ChakraTable>
  );
};

export default Table;
