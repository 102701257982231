import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Select,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import Header from "../../../components/Header/Header";

import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { useEffect, useRef, useState } from "react";
import InputField from "../../../components/InputField";
import SelectField from "../../../components/SelectField";
import * as Yup from "yup";
import { warnValidation } from "../../../services/utils";
import TextareaField from "../../../components/TextareaField";
import ProcessInput from "./components/ProcessInput";
import { savePurchaseOrder } from "../../../services/purchaseOrderService";
import PurchaseOrderPrint from "./components/PurchaseOrderPrint";
import { useReactToPrint } from "react-to-print";
import { PurchaseOrderViewModel } from "../../../models/PurchaseOrder";
import SearchableSelect from "../../../components/SearchableSelect";
import { getCompanies } from "../../../services/companyService";
import { getSuppliers } from "../../../services/supplierService";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const SavePurchaseOrder = () => {
  const formRef = useRef<FormHandles>(null);
  const [currency, setCurrency] = useState("R$");
  const [client, setClient] = useState<{
    id: string;
    name: string;
    cnpj: string;
  }>();
  const navigate = useNavigate();

  useEffect(() => {
    const getClients = async () => {
      const response = await getCompanies(true);
      if (response) setClients(response);
    };
    const getSavedSuppliers = async () => {
      const response = await getSuppliers();
      if (response) setSuppliers(response);
    };
    getClients();
    getSavedSuppliers();
  }, []);

  const schema = Yup.object().shape({
    clientId: Yup.string().required("Este campo é obrigatório."),
    invoice: Yup.string().required("Este campo é obrigatório."),
    supplier: Yup.string().required("Este campo é obrigatório."),
    ncm: Yup.string().required("Este campo é obrigatório."),
    product: Yup.string().required("Este campo é obrigatório."),
    category: Yup.string().required("Este campo é obrigatório."),
    processId: Yup.string().required("Este campo é obrigatório."),
    containerQuantity: Yup.string().required("Este campo é obrigatório."),
    condition: Yup.string().required("Este campo é obrigatório."),
    margin: Yup.string().required("Este campo é obrigatório."),
    payment: Yup.string().required("Este campo é obrigatório."),
  });
  const toast = useToast();

  const [processId, setProcessId] = useState<string>();
  const [loadingSave, setLoadingSave] = useState(false);
  const [suppliers, setSuppliers] = useState<{ name: string }[]>([]);
  const [clients, setClients] = useState<
    {
      id: string;
      name: string;
      cnpj: string;
    }[]
  >([]);

  const handleSubmit = async (data: any) => {
    try {
      const body = {
        ...data,
        processId,
        clientId: client?.id,
        payment: `${currency} ${data.payment}`,
      };
      setLoadingSave(true);
      await schema.validate(body, { abortEarly: false });
      const response = await savePurchaseOrder(body);
      if (response) {
        toast({
          title: "Ordem de compra salva.",
          description:
            "Ordem de compra salva e enviada para os respectivos e-mails para assinatura.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/purchaseOrders");
      }
    } catch (err) {
      warnValidation(err, formRef.current);
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <Box height="100%" overflowX="hidden">
      <Header />
      <Flex
        height="100%"
        pb="64px"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        w="100%"
        backgroundColor="var(--main-bg-color)"
      >
        <Flex
          alignItems="center"
          mb="24px"
          pt="0"
          gap="8px"
          mt={{ base: "16px", md: "27px" }}
          width="90%"
        >
          <Link
            color="var(--icon-color)"
            onClick={() => navigate("/purchaseOrders")}
            size="md"
          >
            Ordens de compra
          </Link>
          <FaAngleRight />
          <Text size="md">Criar ordem de compra</Text>
        </Flex>
        <Flex width={{ base: "95%", md: "45%" }}>
          <Heading size="lg" mb="48px" fontWeight="normal">
            Criar ordem de compra
          </Heading>
        </Flex>

        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box width={{ base: "95%", md: "45%" }}>
            <Flex
              backgroundColor="white"
              flexDirection="column"
              padding={{ base: 0, md: "32px" }}
            >
              <FormControl>
                <Box>
                  <FormLabel>Cliente: </FormLabel>

                  <SearchableSelect
                    options={clients.map((c) => ({
                      value: c.id,
                      label: c.name,
                      subLabel: c.cnpj,
                    }))}
                    name="clientId"
                    value={client?.id}
                    setValue={(value) => {
                      const selClient = clients.find(
                        (c) => c.id.toLowerCase() === value?.toLowerCase()
                      );
                      setClient(selClient);
                    }}
                  />
                  {client && <Text mt="12px">CNPJ: {client.cnpj}</Text>}
                </Box>
              </FormControl>
              <ProcessInput {...{ formRef, processId, setProcessId }} />
              <FormControl>
                <Box w="100%" mt={10}>
                  <FormLabel>Invoice: </FormLabel>
                  <InputField w="100%" name="invoice" placeholder="Invoice" />
                </Box>
              </FormControl>

              <FormControl>
                <Box w="100%" mt={10}>
                  <FormLabel>Fornecedor: </FormLabel>
                  <SelectField
                    w="100%"
                    name="supplier"
                    placeholder="Fornecedor"
                    options={suppliers.map((s) => ({
                      value: s.name,
                      label: s.name,
                    }))}
                  />
                </Box>
              </FormControl>
              <FormControl>
                <Box w="100%" mt={10}>
                  <FormLabel>NCM: </FormLabel>
                  <InputField name="ncm" placeholder="NCM" />
                </Box>
              </FormControl>
              <FormControl>
                <Box w="100%" mt={10}>
                  <FormLabel>Produto: </FormLabel>
                  <InputField name="product" placeholder="Produto" />
                </Box>
              </FormControl>

              <FormControl>
                <Box w="100%" mt={10}>
                  <FormLabel>Categoria: </FormLabel>
                  <InputField name="category" placeholder="Categoria" />
                </Box>
              </FormControl>

              <FormControl>
                <Box w="100%" mt={10}>
                  <FormLabel>Quantidade de containers: </FormLabel>
                  <InputField
                    name="containerQuantity"
                    placeholder="Quantidade de containers"
                  />
                </Box>
              </FormControl>
              <FormControl>
                <Box w="100%" mt={10}>
                  <FormLabel>Condição de pagamento: </FormLabel>
                  <SelectField
                    w="100%"
                    name="condition"
                    placeholder="Condição"
                    options={[
                      {
                        value: "10/90",
                        label: "10/90",
                      },
                      {
                        value: "20/80",
                        label: "20/80",
                      },
                      {
                        value: "30/70",
                        label: "30/70",
                      },
                      {
                        value: "40/60",
                        label: "40/60",
                      },
                      {
                        value: "50/50",
                        label: "50/50",
                      },
                      {
                        value: "100",
                        label: "100",
                      },
                    ]}
                  />
                </Box>
              </FormControl>

              <FormControl>
                <Box w="100%" mt={10}>
                  <FormLabel>Margem: </FormLabel>
                  <InputField name="margin" placeholder="Margem" />
                </Box>
              </FormControl>

              <FormControl>
                <Box w="100%" mt={10}>
                  <FormLabel>Pagamento: </FormLabel>
                  <Flex>
                    <Select
                      w="100px"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <option value="USD">U$</option>
                      <option value="EUR">€</option>
                      <option value="BRL">R$</option>
                    </Select>
                    <InputField
                      mask="currency"
                      name="payment"
                      placeholder="Pagamento"
                    />
                  </Flex>
                </Box>
              </FormControl>
              <FormControl>
                <Box w="100%" mt={10}>
                  <FormLabel>Observação: </FormLabel>
                  <TextareaField name="observation" placeholder="Observação" />
                </Box>
              </FormControl>
            </Flex>
          </Box>
          <Flex w="45%" justifyContent="flex-end">
            <Button
              isLoading={loadingSave}
              backgroundColor="var(--icon-color)"
              type="submit"
              color="white"
              mb="64px"
              my="32px"
            >
              Salvar
            </Button>
          </Flex>
        </Form>
      </Flex>
    </Box>
  );
};
export default SavePurchaseOrder;
