import { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  useDisclosure,
  Button,
  TableContainer,
  Text,
  Spinner,
  Image,
} from "@chakra-ui/react";
import Header from "../../components/Header/Header";
import { getFinancial } from "../../services/processService";
import { Tag } from "../../models/Filter";
import Filter from "../../components/Filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendDown,
  faArrowTrendUp,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { FinancialViewModel } from "../../models/Financial";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import DatePickerField from "../../components/DatePickerField";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import FinancialTable from "./components/FinancialTable";
import FilterModal from "../../components/FilterModal";
import FinancialCard from "./components/FinancialCard";
import { ProcessViewModel } from "../../models/Process";
import FinancialModal from "./components/FinancialModal";
import generatePDF, { Margin, Resolution } from "react-to-pdf";
import SearchableSelect from "../../components/SearchableSelect";
import { CompanyFormData } from "../Configurations/tabs/types";
import { getCompanies } from "../../services/companyService";
import { useCompany } from "../../contexts/CompanyContext";

const Financial = () => {
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState<string>();
  const [clients, setClients] = useState<CompanyFormData[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [page, setPage] = useState<FinancialViewModel>(
    {} as FinancialViewModel
  );
  const [selectedItem, setSelectedItem] = useState<ProcessViewModel>(
    page.processes && page.processes[0]
  );
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();
  const [initialDate, setInitialDate] = useState<Date>();
  const [initialDateValue, setInitialDateValue] = useState<string>();
  const [finalDate, setFinalDate] = useState<Date>();
  const [finalDateValue, setFinalDateValue] = useState<string>();
  const [isPrinting, setIsPrinting] = useState(false);
  const targetRef = useRef(null);
  const options = {
    resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
    },
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
    },
  };
  const { currentCompany } = useCompany();
  const formatterUsd = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "USD",
  });
  const get = async () => {
    setLoading(true);
    const response = await getFinancial({
      initialDate: initialDateValue,
      finalDate: finalDateValue,
      type: currentCompany,
      companyId: client,
      ncm: tags
        .filter((t) => t.type === "ncm")
        ?.map((t) => t.value)
        .join("|"),
      departurePort: tags
        .filter((t) => t.type === "departurePort")
        ?.map((t) => t.value)
        .join("|"),
      cvfRef: tags
        .filter((t) => t.type === "cvfRef")
        ?.map((t) => t.value)
        .join("|"),
      dischargePort: tags
        .filter((t) => t.type === "dischargePort")
        ?.map((t) => t.value)
        .join("|"),
      countryOfOrigin: tags
        .filter((t) => t.type === "origin")
        ?.map((t) => t.value)
        .join("|"),
      exporter: tags
        .filter((t) => t.type === "supplier")
        ?.map((t) => t.value)
        .join("|"),
    });
    setPage(response);
    setLoading(false);
    return response;
  };

  useEffect(() => {
    const getClients = async () => {
      const response = await getCompanies(false);
      if (response) setClients(response);
    };
    getClients();
  }, []);

  useEffect(() => {
    get();
  }, [tags, currentCompany, client]);
  const auth = useAuthUser<any>();

  useEffect(() => {
    if (!auth?.admin) navigate("/");
  }, [auth]);

  useEffect(() => {
    if (initialDate && finalDate) get();
  }, [initialDate, finalDate]);
  const handleGeneratePDF = () => {
    setIsPrinting(true);
    setTimeout(() => {
      setIsPrinting(false);
    }, 600);
  };

  useEffect(() => {
    if (isPrinting)
      setTimeout(() => {
        generatePDF(targetRef, options);
      }, 100);
  }, [isPrinting]);
  console.log("asd-a", auth);
  const extraFilters = [
    <Box>
      <Heading size="sm" mb="8px">
        Início do período
      </Heading>
      <DatePickerField
        width={isMobile ? "80vw" : "16vw"}
        date={initialDate}
        setDate={setInitialDate}
        dateValue={initialDateValue}
        setDateValue={setInitialDateValue}
      />
    </Box>,
    <Box>
      <Heading size="sm" mb="8px">
        Fim do período
      </Heading>
      <DatePickerField
        width={isMobile ? "80vw" : "16vw"}
        date={finalDate}
        setDate={setFinalDate}
        dateValue={finalDateValue}
        setDateValue={setFinalDateValue}
      />
    </Box>,
    <Button
      pos="relative"
      top="24px"
      backgroundColor="var(--icon-color)"
      color="white"
      onClick={handleGeneratePDF}
    >
      Gerar pdf
    </Button>,
  ];
  return (
    <Box height="100%">
      <Header />
      <Flex
        flexDirection="column"
        alignItems="center"
        backgroundColor="var(--main-bg-color)"
        pb="32px"
      >
        <Flex width="90%" mt="48px">
          <Heading size="lg" mb="24px" fontWeight="normal">
            Financeiro
          </Heading>
        </Flex>
        <Flex
          backgroundColor="var(--filter-color)"
          borderRadius="8px"
          width="90%"
          p="32px"
          mb="48px"
        >
          <FilterModal
            extraFilters={
              auth.position > 1
                ? [
                    <Box>
                      <Heading size="sm" mb="8px">
                        Clientes
                      </Heading>
                      <SearchableSelect
                        backgroundColor="white"
                        options={[
                          {
                            value: undefined,
                            label: "Emulada",
                          },
                          ...clients.map((c) => ({
                            value: c.id,
                            label: c.name,
                            subLabel: c.cnpj,
                          })),
                        ]}
                        name="clientId"
                        setValue={(value) => {
                          setClient(value);
                        }}
                      />
                    </Box>,
                    ...extraFilters,
                  ]
                : extraFilters
            }
            {...{
              isOpen,
              onClose,
              tags,
              setTags,
            }}
          />
          {isMobile ? (
            <Flex w="100%" alignItems="center" flexDir="column">
              <Button
                w="80%"
                backgroundColor="var(--icon-color)"
                color="white"
                onClick={onOpen}
              >
                Filtrar Financeiro
              </Button>
            </Flex>
          ) : (
            <Filter
              extraFilters={
                auth.position > 1
                  ? [
                      <Box>
                        <Heading size="sm" mb="8px">
                          Clientes
                        </Heading>
                        <SearchableSelect
                          width={isMobile ? "80vw" : "16vw"}
                          backgroundColor="white"
                          options={[
                            {
                              value: undefined,
                              label: "Emulada",
                            },
                            ...clients.map((c) => ({
                              value: c.id,
                              label: c.name,
                              subLabel: c.cnpj,
                            })),
                          ]}
                          name="clientId"
                          setValue={(value) => {
                            setClient(value);
                          }}
                        />
                      </Box>,
                      ...extraFilters,
                    ]
                  : extraFilters
              }
              {...{
                tags,
                setTags,
              }}
            />
          )}
        </Flex>
        <Box ref={targetRef} w={{ base: "95%", md: "90%" }}>
          {isPrinting && (
            <Flex w="100%" justifyContent="flex-end">
              <Image src="logo.png" height="80px" marginLeft="12px" />
            </Flex>
          )}
          {initialDate && finalDate && (
            <Heading mb="24px" fontWeight="normal" size="md">
              Período: {initialDateValue} - {finalDateValue}
            </Heading>
          )}
          <Flex gap="24px" flexDir={{ base: "column", md: "row" }}>
            <Flex
              p="32px"
              h="180px"
              w={{ base: "100%", md: "50%" }}
              alignItems="center"
              backgroundColor="#F1FCF5"
              borderColor="#8BEAB2"
              borderWidth="2px"
              gap="32px"
              borderRadius="8px"
            >
              {loading ? (
                <Flex w="100%" justifyContent="center" alignItems="center">
                  <Spinner />
                </Flex>
              ) : (
                <>
                  <FontAwesomeIcon
                    fontSize="42px"
                    icon={faArrowTrendUp}
                    color="#27AE60"
                  />
                  <Flex flexDir="column" gap="12px">
                    <Heading color="black" fontWeight="normal" size="md">
                      Total pago
                    </Heading>
                    <Heading color="#27AE60" size="lg">
                      {formatterUsd.format(page?.totalPayed ?? 0)}
                    </Heading>

                    {page.totalPayedBefore && (
                      <Flex
                        w="100%"
                        fontWeight="normal"
                        justifyContent="center"
                      >
                        <Text
                          fontWeight="semibold"
                          size="sm"
                          color={
                            (page.totalPayedBefore ?? 0) > 0 ? "green" : "red"
                          }
                        >
                          <Flex gap="4px" mr="4px" alignItems="center">
                            {(page.totalPayedBefore ?? 0) > 0 ? (
                              <FaArrowUp />
                            ) : (
                              <FaArrowDown />
                            )}
                            {Math.abs(page.totalPayedBefore)}%
                          </Flex>
                        </Text>
                        <Text size="sm">
                          {" "}
                          {(page.totalPayedBefore ?? 0) > 0
                            ? "maior"
                            : "menor"}{" "}
                          que o período anterior
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </>
              )}
            </Flex>
            <Flex
              p="32px"
              h="180px"
              w={{ base: "100%", md: "50%" }}
              alignItems="center"
              backgroundColor="#FEF3F2"
              borderColor="#F9B0A8"
              borderWidth="2px"
              gap="32px"
              borderRadius="8px"
            >
              {loading ? (
                <Flex w="100%" justifyContent="center" alignItems="center">
                  <Spinner />
                </Flex>
              ) : (
                <>
                  <FontAwesomeIcon
                    fontSize="42px"
                    icon={faArrowTrendDown}
                    color="#E74C3C"
                  />
                  <Flex flexDir="column" gap="12px">
                    <Heading color="black" fontWeight="normal" size="md">
                      Total a pagar
                    </Heading>
                    <Heading color="#E74C3C" size="lg">
                      {formatterUsd.format(page?.totalToPay ?? 0)}
                    </Heading>
                    {page.totalToPayBefore && (
                      <Flex
                        w="100%"
                        fontWeight="normal"
                        justifyContent="center"
                      >
                        <Text
                          fontWeight="semibold"
                          size="sm"
                          color={
                            (page.totalToPayBefore ?? 0) > 0 ? "green" : "red"
                          }
                        >
                          <Flex gap="4px" mr="4px" alignItems="center">
                            {(page.totalToPayBefore ?? 0) > 0 ? (
                              <FaArrowUp />
                            ) : (
                              <FaArrowDown />
                            )}
                            {Math.abs(page.totalToPayBefore)}%
                          </Flex>
                        </Text>
                        <Text size="sm"> maior que o período anterior</Text>
                      </Flex>
                    )}
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>

          <FinancialModal
            p={selectedItem}
            onClose={onCloseDetail}
            isOpen={isOpenDetail}
          />
          {isMobile || isPrinting ? (
            <Flex flexDir="column" gap="12px" mt="42px">
              {(
                (page?.processes ?? [])
                  .filter((p) => p.financialItems.length > 0)
                  .map((p) => ({
                    ...p,
                    items: p.financialItems.filter(
                      (f) => (f.valorPagamento?.length ?? 0) > 1
                    ),
                  })) ?? []
              ).map((p) => (
                <FinancialCard
                  onOpen={onOpenDetail}
                  {...{ setSelectedItem }}
                  p={p as ProcessViewModel}
                />
              ))}
            </Flex>
          ) : (
            <Flex backgroundColor="white" flexDirection="column" mt="42px">
              <Box padding={isMobile ? 0 : "32px"}>
                <TableContainer
                  height={isPrinting ? undefined : "500px"}
                  overflowY="auto"
                >
                  <FinancialTable
                    {...{ selectedItem, setSelectedItem, onOpenDetail }}
                    data={
                      (page?.processes ?? [])
                        .filter((p) => p.financialItems.length > 0)
                        .map((p) => ({
                          ...p,
                          items: p.financialItems.filter(
                            (f) => (f.valorPagamento?.length ?? 0) > 1
                          ),
                        })) ?? []
                    }
                  />
                </TableContainer>
              </Box>
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default Financial;
