import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";

import { BrowserRouter } from "react-router-dom";
import AuthProvider from "react-auth-kit";
import createStore from "react-auth-kit/createStore";
import { createTheme, ThemeProvider } from "@mui/material";
import { CompanyProvider } from "./contexts/CompanyContext";

const store = createStore({
  authName: "auth",
  authType: "localstorage",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
});
const theme = createTheme({
  typography: {
    fontFamily: "Inter",
  },
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <AuthProvider store={store}>
      <ThemeProvider theme={theme}>
        <ChakraProvider>
          <BrowserRouter>
            <CompanyProvider>
              <App />
            </CompanyProvider>
          </BrowserRouter>
        </ChakraProvider>
      </ThemeProvider>
    </AuthProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
