import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Image,
  useToast,
  Link,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { AxiosError } from "axios";
import { changePassword } from "../../services/userService";
import { FieldValue, useForm } from "react-hook-form";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import Header from "../../components/Header/Header";
import { useMediaQuery } from "react-responsive";
import { DateTime, Duration } from "luxon";
import { FaAngleRight } from "react-icons/fa";

const ChangePassword = () => {
  const { register, handleSubmit, formState, getValues, reset } = useForm();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const url = useSearchParams();

  const handleShowClick = () => setShowPassword(!showPassword);
  const toast = useToast();
  const navigate = useNavigate();

  const signIn = useSignIn();

  const change = async (data: any) => {
    setLoading(true);

    try {
      const response = await changePassword({
        currentPassword: data.currentPassword,
        password: data.password,
        token,
      });

      if (response?.data.success) {
        navigate(isAuth ? "/account" : "/login");
        toast({
          title: "Senha alterada.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      } else {
        setError(response?.data.notifications[0]);
      }
    } catch (err) {
      if (err && err instanceof AxiosError) console.log("Error: ", err);
    }
    setLoading(false);
  };

  const isAuth = useIsAuthenticated();

  useEffect(() => {
    const tokenUrl = url[0].get("token");
    if (tokenUrl?.length === 36) {
      setToken(tokenUrl);
    }
  }, []);

  const validateRequired = (value: FieldValue<Record<string, any>>) =>
    !value || /^\s*$/.test(value) ? "Esse campo é obrigatório." : undefined;

  return (
    <Box height="100%" overflow="hidden">
      {isAuth && <Header />}
      <Flex
        backgroundColor="var(--main-bg-color)"
        width="100%"
        height="100vh"
        flexDir="column"
        justifyContent={isAuth ? undefined : "center"}
        alignItems="center"
      >
        {isAuth && (
          <Flex
            alignItems="center"
            gap="8px"
            width={{ base: "90%", md: "50%" }}
            mt={{ base: "16px", md: "27px" }}
          >
            <Link
              color="var(--icon-color)"
              onClick={() => navigate("/account")}
              size="md"
            >
              Configurações da conta
            </Link>
            <FaAngleRight />
            <Text size="md">Alterar senha</Text>
          </Flex>
        )}

        <Flex style={{ width: isMobile ? "90%" : "30%" }} mt="48px">
          <Heading size="lg" mb="24px" fontWeight="normal">
            Alterar senha
          </Heading>
        </Flex>
        <form
          onSubmit={handleSubmit(change)}
          style={{ width: isMobile ? "90%" : "30%" }}
        >
          <Flex
            borderRadius="8px"
            position="relative"
            backgroundColor="white"
            padding="24px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            w="100%"
          >
            {isAuth && (
              <Box mb="64px" w="100%">
                <Flex>
                  <Heading size="sm" marginBottom="12px">
                    Senha atual
                  </Heading>
                  <Text color="red" fontSize="18px" marginLeft="4px">
                    *
                  </Text>
                </Flex>
                <InputGroup>
                  <Input
                    borderColor={
                      formState.errors.currentPassword ? "red" : undefined
                    }
                    type={showPassword ? "text" : "password"}
                    placeholder="Senha"
                    {...register("currentPassword", {
                      required: "Esse campo é obrigatório.",
                    })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      backgroundColor="transparent"
                      size="sm"
                      onClick={handleShowClick}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {formState.errors.currentPassword && (
                  <div
                    style={{
                      color: "red",
                      marginTop: "16px",
                    }}
                  >
                    {typeof formState.errors.currentPassword.message ===
                      "string" && formState.errors.currentPassword.message}
                  </div>
                )}
              </Box>
            )}
            <Box mb="64px" w="100%">
              <Flex>
                <Heading size="sm" marginBottom="12px">
                  Senha
                </Heading>
                <Text color="red" fontSize="18px" marginLeft="4px">
                  *
                </Text>
              </Flex>
              <InputGroup>
                <Input
                  borderColor={formState.errors.password ? "red" : undefined}
                  type={showPassword ? "text" : "password"}
                  placeholder="Senha"
                  {...register("password", {
                    required: "Esse campo é obrigatório.",
                  })}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    backgroundColor="transparent"
                    size="sm"
                    onClick={handleShowClick}
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon icon={faEye} />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {formState.errors.password && (
                <div
                  style={{
                    color: "red",
                    marginTop: "16px",
                    marginBottom: "-40px",
                  }}
                >
                  {typeof formState.errors.password.message === "string" &&
                    formState.errors.password.message}
                </div>
              )}
            </Box>

            <Box w="100%">
              <Flex>
                <Heading size="sm" marginBottom="12px">
                  Confirmar senha
                </Heading>
                <Text color="red" fontSize="18px" marginLeft="4px">
                  *
                </Text>
              </Flex>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  borderColor={
                    formState.errors.confirmPassword ? "red" : undefined
                  }
                  placeholder="Confirmar senha"
                  {...register("confirmPassword", {
                    required: "Esse campo é obrigatório.",
                    validate: (value: FieldValue<Record<string, any>>) => {
                      const requiredValid = validateRequired(value);
                      const isEqual = getValues().password == value;

                      return (
                        requiredValid ??
                        (isEqual ? undefined : "Senhas devem coincidir")
                      );
                    },
                  })}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    backgroundColor="transparent"
                    size="sm"
                    onClick={handleShowClick}
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon icon={faEye} />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {(formState.errors.confirmPassword || error) && (
                <div
                  style={{
                    color: "red",
                    marginTop: "16px",
                  }}
                >
                  {error ??
                    (typeof formState.errors.confirmPassword?.message ===
                      "string" &&
                      formState.errors.confirmPassword.message)}
                </div>
              )}
            </Box>
          </Flex>
          {
            <Flex
              w="100%"
              justifyContent="flex-end"
              marginY={{ base: "32px", md: "64px" }}
              gap="16px"
            >
              <Button
                width="120px"
                backgroundColor="transparent"
                color="var(--icon-color)"
                borderColor="var(--icon-color)"
                borderWidth="2px"
                borderRadius="4px"
                isLoading={loading}
                onClick={() => navigate(-1)}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                isLoading={loading}
                color="white"
                borderRadius="4px"
                width="120px"
                backgroundColor="var(--icon-color)"
              >
                Salvar
              </Button>
            </Flex>
          }
        </form>
      </Flex>
    </Box>
  );
};
export default ChangePassword;
