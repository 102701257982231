import { Flex, Heading, useDisclosure } from "@chakra-ui/react";
import ProcessesModal from "./ProcessesModal";
import Flag from "react-world-flags";
import { GraphValueViewModel } from "../../../models/Dashboard";

const DataListItem = ({
  v,
  isPrinting,
  property,
  graphType,
  formatter,
}: {
  v: GraphValueViewModel;
  isPrinting: boolean;
  graphType: string;
  property: string;
  formatter: Intl.NumberFormat;
}) => {
  const {
    isOpen: isOpenProcess,
    onOpen: onOpenProcess,
    onClose: onCloseProcess,
  } = useDisclosure();

  return (
    <Flex justifyContent="space-between" onClick={onOpenProcess}>
      <ProcessesModal
        filter={{ property: property, value: v.label }}
        isOpen={isOpenProcess}
        onClose={onCloseProcess}
      />
      <Flex gap="24px">
        {!isPrinting && <Flag code={v.code} width="42px" />}
        <Heading size="sm" fontWeight="normal">
          {v.label}
        </Heading>
      </Flex>
      <Heading size="sm">
        {graphType === "1" ? formatter.format(v.usd) : v.numberOfProcesses}
      </Heading>
    </Flex>
  );
};

export default DataListItem;
