import React, {
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
  useToast,
  VStack,
  HStack,
  GridItem,
} from "@chakra-ui/react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { warnValidation } from "../../../../../services/utils";
import InputField from "../../../../../components/InputField";
import TextareaField from "../../../../../components/TextareaField";
import { AddressFormData, CompanyFormData, ContactFormData } from "../../types";
import {
  getCompany,
  saveCompany,
  updateCompany,
} from "../../../../../services/companyService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";

interface CompanyFormProps {
  id?: string;
  set: Dispatch<SetStateAction<any>>;
  get: () => void;
}

const CompanyForm = ({ id, set, get }: CompanyFormProps) => {
  const [initialData, setInitialData] = useState<CompanyFormData>();
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();

  const toast = useToast();
  const isEdit = !!id;

  const [addresses, setAddresses] = useState<AddressFormData[]>(
    initialData?.addresses || []
  );
  const [contacts, setContacts] = useState<ContactFormData[]>(
    initialData?.contacts || []
  );

  async function handleSubmit(data: CompanyFormData) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required("Campo obrigatório"),
        cnpj: Yup.string().required("Campo obrigatório"),
        addresses: Yup.array().of(
          Yup.object().shape({
            street: Yup.string().required("Campo obrigatório"),
            number: Yup.string().required("Campo obrigatório"),
            city: Yup.string().required("Campo obrigatório"),
            state: Yup.string().required("Campo obrigatório"),
            zipCode: Yup.string().required("Campo obrigatório"),
          })
        ),
        contacts: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required("Nome é obrigatório"),
          })
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // Filter out empty addresses and contacts
      data.addresses = data.addresses.filter(
        (addr) =>
          addr.street || addr.city || addr.state || addr.zipCode || addr.country
      );

      data.contacts = data.contacts.filter(
        (contact) => contact.name || contact.email || contact.phoneNumber
      );

      // Add id if editing
      if (isEdit) {
        data.id = id;
      }

      const response = isEdit ? updateCompany(data) : saveCompany(data);

      toast({
        title: isEdit ? "Cliente atualizado!" : "Cliente cadastrado!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      set(undefined);
      await get();
    } catch (err) {
      warnValidation(err, formRef.current);
      toast({
        title: "Erro no formulário",
        description:
          "Ocorreu um erro ao processar o formulário, confira os dados.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const addAddress = () => {
    setAddresses([
      ...addresses,
      {
        street: "",
        number: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
      },
    ]);
  };

  const removeAddress = (index: number) => {
    const newAddresses = [...addresses];
    newAddresses.splice(index, 1);
    setAddresses(newAddresses);
  };

  const addContact = () => {
    setContacts([...contacts, { name: "", email: "", phoneNumber: "" }]);
  };

  const removeContact = (index: number) => {
    const newContacts = [...contacts];
    newContacts.splice(index, 1);
    setContacts(newContacts);
  };

  const getSavedCompany = async () => {
    const response = await getCompany(id ?? "");
    if (response) {
      console.log("asd-rrrr", response);
      setInitialData(response);
      setContacts(response.contacts);
      setAddresses(response.addresses);
    }
  };

  useEffect(() => {
    if (id) {
      getSavedCompany();
    }
  }, [id]);
  return (
    <Box>
      <Heading size="lg" mb="24px" fontWeight="normal">
        <Flex alignItems="center" gap="12px">
          {isEdit ? "Editar Cliente" : "Cadastrar Cliente"}
        </Flex>
      </Heading>
      {(isEdit ? initialData : !isEdit) && (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
          <VStack spacing={6} align="stretch">
            <Stack spacing={4}>
              <Heading size="md" fontWeight="semibold">
                Informações Básicas
              </Heading>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                <InputField name="name" label="Nome da Empresa" />
                <InputField name="cnpj" label="CNPJ" mask="cnpj" />
              </SimpleGrid>
              <TextareaField name="observation" label="Observações" />
            </Stack>

            <Divider />

            <Stack spacing={4}>
              <Flex justify="space-between" align="center">
                <Heading size="md" fontWeight="semibold">
                  Endereços
                </Heading>
                <Button
                  leftIcon={<FiPlus />}
                  colorScheme="blue"
                  size="sm"
                  onClick={addAddress}
                >
                  Adicionar Endereço
                </Button>
              </Flex>

              {addresses.map((_, index) => (
                <Box
                  key={index}
                  p={4}
                  borderWidth="1px"
                  borderRadius="md"
                  position="relative"
                >
                  <Flex justifyContent="flex-end">
                    <IconButton
                      aria-label="Remover endereço"
                      icon={<FiTrash2 />}
                      size="sm"
                      colorScheme="red"
                      isDisabled={addresses.length === 1}
                      onClick={() => removeAddress(index)}
                    />
                  </Flex>

                  <VStack spacing={4} align="stretch">
                    <SimpleGrid columns={{ base: 1, md: 4 }} spacing={4}>
                      <GridItem colSpan={3}>
                        <Box display="none">
                          <InputField
                            name={`addresses[${index}].id`}
                            label="id"
                          />
                        </Box>
                        <InputField
                          name={`addresses[${index}].street`}
                          label="Rua"
                        />
                      </GridItem>
                      <InputField
                        name={`addresses[${index}].number`}
                        label="Número"
                      />
                    </SimpleGrid>

                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                      <InputField
                        name={`addresses[${index}].city`}
                        label="Cidade"
                      />
                      <InputField
                        name={`addresses[${index}].state`}
                        label="Estado"
                      />
                      <InputField
                        name={`addresses[${index}].zipCode`}
                        label="CEP"
                        mask="cep"
                      />
                    </SimpleGrid>

                    <InputField
                      name={`addresses[${index}].country`}
                      label="País"
                    />
                  </VStack>
                </Box>
              ))}
            </Stack>

            <Divider />

            <Stack spacing={4}>
              <Flex justify="space-between" align="center">
                <Heading size="md" fontWeight="semibold">
                  Contatos
                </Heading>
                <Button
                  leftIcon={<FiPlus />}
                  colorScheme="blue"
                  size="sm"
                  onClick={addContact}
                >
                  Adicionar Contato
                </Button>
              </Flex>

              {contacts.map((contact, contactIndex) => (
                <Box
                  key={contactIndex}
                  p={4}
                  borderWidth="1px"
                  borderRadius="md"
                  position="relative"
                >
                  <Flex justifyContent="flex-end">
                    <IconButton
                      aria-label="Remover contato"
                      icon={<FiTrash2 />}
                      size="sm"
                      colorScheme="red"
                      isDisabled={contacts.length === 1}
                      onClick={() => removeContact(contactIndex)}
                    />
                  </Flex>
                  <VStack spacing={4} align="stretch">
                    <Box display="none">
                      <InputField
                        name={`contacts[${contactIndex}].id`}
                        label="id"
                      />
                    </Box>
                    <InputField
                      name={`contacts[${contactIndex}].name`}
                      label="Nome"
                    />

                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                      <InputField
                        name={`contacts[${contactIndex}].email`}
                        label="Email"
                        type="email"
                      />
                      <InputField
                        name={`contacts[${contactIndex}].phoneNumber`}
                        label="Telefone"
                        mask="mobile"
                      />
                    </SimpleGrid>
                  </VStack>
                </Box>
              ))}
            </Stack>

            <Flex justify="flex-end" mt={6}>
              <HStack spacing={4}>
                <Button onClick={() => set(undefined)}>Cancelar</Button>
                <Button type="submit" colorScheme="blue">
                  Salvar
                </Button>
              </HStack>
            </Flex>
          </VStack>
        </Form>
      )}
    </Box>
  );
};

export default CompanyForm;
