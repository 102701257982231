import { Box, Flex, Text } from "@chakra-ui/react";
import Card from "./Components/Card";
import Header from "../../components/Header/Header";
import { options } from "../../models/Home";
import { useEffect } from "react";
import { getProcessById } from "../../services/processService";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const Home = () => {
  const auth = useAuthUser<any>();

  useEffect(() => {
    getProcessById("");
  }, []);
  return (
    <Box height="100vh" overflow={"hidden"} fontFamily="Inter">
      <Header isHome />
      <Flex
        height="90vh"
        alignItems="center"
        flexDirection="column"
        backgroundColor="var(--main-bg-color)"
        paddingBottom="32px"
      >
        <Box zIndex={1} overflowX="auto">
          <Text
            fontWeight="normal"
            fontSize="34px"
            mt={{ base: "64px", md: "120px" }}
            mb={{ base: "16px", md: "32px" }}
            size="lg"
            px="16px"
            textAlign="center"
          >
            Olá {auth?.firstname}, o que faremos hoje?
          </Text>
          <Flex w="100vw" justifyContent="center">
            <Flex
              gap="12px"
              flexDir={{ base: "column", md: "row" }}
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              overflowX="auto"
            >
              {options
                .filter((o) =>
                  auth?.position === 1
                    ? o.link === "/processes"
                    : auth?.position > 1 || o.link != "/purchaseOrders"
                )
                .map((option) => (
                  <Box>
                    <Card key={option.link} {...{ option }} />
                  </Box>
                ))}
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
export default Home;
