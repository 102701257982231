import api from "./api";
import { EditProcessCommand } from "../models/Process";
import { PurchaseOrderQuery } from "../models/PurchaseOrder";

export const getPurchaseOrders = async (filter?: PurchaseOrderQuery) => {
  try {
    const response = await api.get(`/api/purchaseOrder`, { params: filter });
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const savePurchaseOrder = async (process: EditProcessCommand) => {
  try {
    const response = await api.post(`/api/purchaseOrder`, process);
    if (!response.data.success) {
      console.log("asd-res", response.data);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const signPurchaseOrder = async (id: string, position: string) => {
  try {
    const response = await api.put(
      `/api/purchaseOrder/${id}/Assinar/${position}`
    );
    if (!response.data.success) {
      console.log("asd-res", response.data);
    }
    return response;
  } catch (error) {
    throw error;
  }
};
