import {
  Box,
  Text,
  VStack,
  HStack,
  Circle,
  Divider,
  Flex,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import EditingTrack from "./editingTrack";

const TimelineItem = ({
  isTransshipment = false,
  isActual = false,
  place,
  arrivalDate,
  isStatus = false,
  departureDate,
  id,
  isFirst = false,
  get,
  processId,
}: {
  isTransshipment?: boolean;
  isActual: boolean;
  place?: string;
  id?: string;
  arrivalDate?: string;
  departureDate?: string;
  isFirst?: boolean;
  isStatus?: boolean;
  get?: () => void;
  processId?: string;
}) => {
  const [editing, setEditing] = useState(false);
  const auth = useAuthUser<any>();
  return (
    <HStack alignItems="flex-end" spacing={4}>
      {isFirst && !isTransshipment ? (
        <VStack spacing={0}>
          {/* Circle for each point */}
          {isActual ? (
            <Circle size="12px" bg="var(--icon-color)" />
          ) : (
            <Circle
              size="12px"
              borderWidth={"2px"}
              borderColor="var(--icon-color)"
            />
          )}
        </VStack>
      ) : (
        <VStack spacing={0}>
          {/* Line below the circle */}
          {isActual ? (
            <Box
              w="2px"
              h={isTransshipment && !isFirst ? "24px" : "64px"}
              bg="var(--icon-color)"
            />
          ) : (
            <Box
              w="2px"
              h={isTransshipment && !isFirst ? "24px" : "64px"}
              borderLeft="2px dashed var(--icon-color)"
            />
          )}
          {/* Circle for each point */}
          {isActual ? (
            <Circle size="12px" bg="var(--icon-color)" />
          ) : (
            <Circle
              size="12px"
              borderWidth={"2px"}
              borderColor="var(--icon-color)"
            />
          )}
        </VStack>
      )}
      {editing ? (
        <EditingTrack
          d={{ id: id, dateTime: departureDate, status: place }}
          {...{ processId, setEditing, get }}
        />
      ) : (
        <Flex
          pos="relative"
          top="6px"
          justifyContent="space-between"
          alignItems="center"
          w="100%"
        >
          {/* Place and date */}
          <VStack align="start" spacing={0}>
            <Text fontSize="lg" fontWeight="semibold">
              {isStatus
                ? ""
                : arrivalDate
                ? isActual
                  ? "Chegou em"
                  : "Chegará em"
                : isActual
                ? "Saiu de"
                : "Sairá de"}{" "}
              {place}
            </Text>
          </VStack>

          <Text fontSize="sm" color="gray.500">
            {DateTime.fromISO(arrivalDate ?? departureDate ?? "").toFormat(
              isStatus ? "dd/LL/yyyy HH:mm" : "dd/LL/yyyy"
            )}
          </Text>
        </Flex>
      )}
      {auth.position > 1 && !editing && isStatus && (
        <FaPencilAlt
          style={{ cursor: "pointer" }}
          onClick={() => setEditing(true)}
        />
      )}
    </HStack>
  );
};

export default TimelineItem;
