import api from "./api";

export const getSuppliers = async () => {
  try {
    const response = await api.get(`/api/supplier`);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const saveSupplier = async (name: string) => {
  try {
    const response = await api.post(`/api/supplier`, {
      name,
    });
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const importSuppliers = async (file: File) => {
  try {
    let formData = new FormData();
    if (file) {
      formData.append("file", new File([file], file.name));
    }
    const response = await api.post("/api/supplier/import", formData);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (err) {}
};

export const deleteSupplier = async (supplierId: string) => {
  try {
    const response = await api.delete(`/api/supplier/${supplierId}`);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};
