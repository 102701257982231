import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getCompanies } from "../../../services/companyService";
import { FaFileExcel, FaTrash, FaTruckLoading } from "react-icons/fa";
import {
  deleteSupplier,
  getSuppliers,
  saveSupplier,
} from "../../../services/supplierService";
import ImportModal from "./Company/components/ImportModal";

const AddSuppliers = () => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>();
  const [search, setSearch] = useState<string>();
  const [suppliers, setSuppliers] = useState<{ id: string; name: string }[]>(
    []
  );
  const [filteredSuppliers, setFilteredSuppliers] =
    useState<{ id: string; name: string }[]>(suppliers);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleSave = async () => {
    setError(undefined);
    if ((name?.length ?? 0) === 0) {
      setError("Este campo é obrigatório");
      return;
    }
    setLoading(true);
    const response = await saveSupplier(name ?? "");
    if (response) {
      toast({
        title: "Fornecedor adicionado.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      await getSavedSuppliers();
    }
    setLoading(false);
  };

  const remove = async (id: string) => {
    setLoading(true);
    await deleteSupplier(id);
    await getSavedSuppliers();
    toast({
      title: "Fornecedor removido.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    setLoading(false);
  };
  const getSavedSuppliers = async () => {
    const response = await getSuppliers();
    if (response) {
      setSuppliers(response);
      setFilteredSuppliers(response);
    }
  };
  useEffect(() => {
    getSavedSuppliers();
  }, []);
  return (
    <Box>
      <Heading size="lg" mt="48px" mb="24px" fontWeight="normal">
        <Flex alignItems="center" gap="12px">
          <FaTruckLoading color="var(--icon-color)" /> Adicionar Fornecedores
        </Flex>
      </Heading>
      <Button
        color="white"
        borderRadius="4px"
        backgroundColor="#1C6C3F"
        onClick={() => onOpen()}
      >
        <Flex justifyContent="center" alignItems="center" gap="8px">
          <FaFileExcel color="white" /> Importar
        </Flex>
      </Button>
      <ImportModal
        isSupplier
        getSavedCompanies={getSavedSuppliers}
        {...{ isOpen, onClose }}
      />
      <form>
        <Box width="100%" backgroundColor="white" borderRadius="8px" p="24px">
          <Flex flexDirection="column">
            <Flex>
              <Heading size="sm" fontWeight="normal" marginBottom="8px">
                Nome do fornecedor
              </Heading>
              <Text color="red" fontSize="18px" marginLeft="4px">
                *
              </Text>
            </Flex>
            <Input
              value={name}
              onChange={(e) => {
                setError(undefined);
                setName(e.target.value);
              }}
            />
            {error && (
              <Text marginTop="16px" color="red">
                {error}
              </Text>
            )}
          </Flex>
        </Box>
        <Flex w="100%" justifyContent="flex-end">
          <Button
            marginY="32px"
            backgroundColor="var(--icon-color)"
            color="white"
            onClick={() => (error?.length ?? 0) === 0 && handleSave()}
            isLoading={loading}
            type="submit"
          >
            Salvar
          </Button>
        </Flex>

        <Flex
          flexDir="column"
          marginTop="32px"
          overflowY="auto"
          backgroundColor="white"
          borderRadius="8px"
          p="24px"
        >
          <Heading size="md" fontWeight="normal">
            Fornecedores salvos
          </Heading>
          <Input
            placeholder="Pesquise um fornecedor"
            my="32px"
            height="40px !important"
            onChange={(e) =>
              setFilteredSuppliers((fs) => {
                const newfs = [...suppliers];
                return newfs.filter((f) =>
                  f.name.toLowerCase().includes(e.target.value.toLowerCase())
                );
              })
            }
          />
          <Flex flexDir="column" gap="16px" h="400px" overflowY="auto">
            {filteredSuppliers.map((p) => (
              <Flex alignItems="center" gap="8px">
                <Heading size="sm" fontWeight="normal" w="95%">
                  {p.name}
                </Heading>
                <FaTrash onClick={() => remove(p.id)} cursor="pointer" />
              </Flex>
            ))}
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};

export default AddSuppliers;
