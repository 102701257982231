import {
  Flex,
  Heading,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import HeaderMenuContent from "./HeaderMenuContent";

const HeaderMenu = () => {
  return (
    <Popover>
      <PopoverTrigger>
        <Flex
          alignItems="center"
          justifyContent="center"
          gap="8px"
          cursor="pointer"
        >
          <FontAwesomeIcon style={{ top: "3px" }} icon={faBars} />
          <Heading size="md">Menu</Heading>
        </Flex>
      </PopoverTrigger>
      <PopoverContent width="300px">
        <HeaderMenuContent />
      </PopoverContent>
    </Popover>
  );
};

export default HeaderMenu;
