import { DashboardQuery } from "./Dashboard";
import { ProcessViewModel } from "./Process";

export interface ProcessQuery {
  dateType?: string;
  initialDate?: string;
  finalDate?: string;
  ncm?: string;
  departurePort?: string;
  dischargePort?: string;
  companyId?: string;
  origin?: string;
  supplier?: string;
}

export interface PurchaseOrderViewModel {
  id: string;
  cvfRef?: string;
  poNumber: number;
  fob?: string;
  condition?: string;
  importMode?: string;
  valorPagamento?: string;
  totalPayed?: number;
  taxaCambioPago?: string;
  totalToPay?: number;
  taxaCambioPagar?: string;
  dataPagamento?: string;
  corporateReason?: string;
  cnpj?: string;
  invoice?: string;
  supplier?: string;
  ncm?: string;
  product?: string;
  category?: string;
  containerQuantity?: string;
  payment?: string;
  margin?: string;
  createdDate: string;
  incoterm?: string;
  observation?: string;
  processId?: string;
  status: boolean;
  process: ProcessViewModel;
  administrationSigned: boolean;
  administrationSignedBy: string;
  administrationSignedDate: string;
  commercialSigned: boolean;
  commercialSignedBy: string;
  commercialSignedDate: string;
  financialSigned: boolean;
  financialSignedBy: string;
  financialSignedDate: string;
  operationalSigned: boolean;
  operationalSignedBy: string;
  operationalSignedDate: string;
}

export interface PurchaseOrderCommand {
  corporateReason?: string;
  cnpj?: string;
  invoice?: string;
  supplier?: string;
  ncm?: string;
  product?: string;
  category?: string;
  containerQuantity?: number;
  payment?: string;
  margin?: string;
  condition?: string;
  observation?: string;
  processId?: string;
}

export interface PurchaseOrderQuery extends DashboardQuery {
  corporateReason?: string;
  cnpj?: string;
  invoice?: string;
  supplier?: string;
  ncm?: string;
  product?: string;
  category?: string;
  containerQuantity?: number;
  payment?: string;
  margin?: string;
  condition?: string;
  observation?: string;
  processId?: string;
  poNumber?: string;
  pageNumber?: number;
  status?: boolean;
  pageSize?: number;
}

export const positions = [
  { key: 0, label: "Cliente", value: "Client" },
  { key: 1, label: "Administrativo", value: "Administration" },
  { key: 2, label: "Operacional", value: "Operational" },
  { key: 3, label: "Comercial", value: "Commercial" },
  { key: 4, label: "Financeiro", value: "Financial" },
];
