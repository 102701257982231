import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { DateTime } from "luxon";

export const formatCPF = (value: string) => {
  if (!value) return "";
  let formattedValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos
  formattedValue = formattedValue.slice(0, 11); // Limita o tamanho da string

  if (formattedValue.length > 9) {
    // Insere pontos e hífen nas posições corretas
    formattedValue = `${formattedValue.slice(0, 3)}.${formattedValue.slice(
      3,
      6
    )}.${formattedValue.slice(6, 9)}-${formattedValue.slice(9)}`;
  } else if (formattedValue.length > 6) {
    // Insere pontos nas posições corretas
    formattedValue = `${formattedValue.slice(0, 3)}.${formattedValue.slice(
      3,
      6
    )}.${formattedValue.slice(6)}`;
  } else if (formattedValue.length > 3) {
    formattedValue = `${formattedValue.slice(0, 3)}.${formattedValue.slice(3)}`;
  }

  return formattedValue;
};

export const validateCPF = (cpf: string) => {
  cpf = cpf.replace(/\D/g, ""); // Remove caracteres não numéricos

  // Verifica se o CPF tem 11 dígitos e não é uma sequência repetida
  if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
    return false;
  }

  // Calcula o dígito verificador do CPF
  const digit1 = parseInt(cpf.charAt(9));
  const digit2 = parseInt(cpf.charAt(10));
  let sum = 0;
  let weight = 10;

  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * weight;
    weight--;
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (result !== digit1) {
    return false;
  }

  sum = 0;
  weight = 11;

  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * weight;
    weight--;
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  return result === digit2;
};

export const formatDateTime = (oldValue: string | undefined, value: string) => {
  if ((oldValue?.length ?? 0) > value.length) {
    return value;
  }
  let formattedValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos
  formattedValue = formattedValue.slice(0, 12); // Limita o tamanho da string

  if (formattedValue.length > 8) {
    // Insere barras e dois pontos nas posições corretas
    formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(
      2,
      4
    )}/${formattedValue.slice(4, 8)} ${formattedValue.slice(
      8,
      10
    )}:${formattedValue.slice(10)}`;
  } else if (formattedValue.length > 4) {
    // Insere barras nas posições corretas
    formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(
      2,
      4
    )}/${formattedValue.slice(4, 8)} ${formattedValue.slice(8)}`;
  } else if (formattedValue.length > 2) {
    formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`;
  }

  return formattedValue;
};

export function cep(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "$1$2");
  value = value.replace(/(\d{3})(\d{1,3})$/, "$1-$2");
  e.currentTarget.value = value;
  return e;
}

export function currency(e: React.FormEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  e.currentTarget.value = value;
  return e;
}

export function cpf(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    e.currentTarget.value = value;
  }
  return e;
}

export function cnpj(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 18;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{2}).(\d{3}).(\d{3})\/\/(\d{4})-(\d{2})$/)) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1/$2");
    value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    e.currentTarget.value = value;
  }
  return e;
}

export function phone(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{2})(\d{4})-(\d{3})$/)) {
    value = value.replace(/\D/g, "");

    value = value.replace(/(\d{2})(\d)/, "($1) $2");
    value = value.replace(/(\d{4})(\d)/, "$1-$2");
    value = value.replace(/(\d{4})(\d)$/, "$1-$2");
    e.currentTarget.value = value;
  }
  return e;
}

export function formatMobile(value: string) {
  let val = value;
  if (!val.match(/^(\d{2}) (\d{1}) (\d{4})-(\d{4})$/)) {
    val = val.replace(/\D/g, "");

    val = val.replace(/(\d{2})(\d{1})(\d)/, "($1) $2 $3");
    val = val.replace(/(\d{4})(\d)/, "$1-$2");
    val = val.replace(/(\d{4})(\d)$/, "$1-$2-$3");
  }
  return val;
}

export function mobile(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 16;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{2}) (\d{1}) (\d{4})-(\d{4})$/)) {
    value = value.replace(/\D/g, "");

    value = value.replace(/(\d{2})(\d{1})(\d)/, "($1) $2 $3");
    value = value.replace(/(\d{4})(\d)/, "$1-$2");
    value = value.replace(/(\d{4})(\d)$/, "$1-$2-$3");
    e.currentTarget.value = value.substring(0, 16);
  }
  return e;
}

export function date(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 10;
  let value = e.currentTarget.value;
  if (!value.match(/^\d{2}[/]\d{2}[/]\d{4}$/)) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    value = value.replace(/(\d{4})(\d{1,4})$/, "$1/$2");
    e.currentTarget.value = value;
  }
  return e;
}

export function cepString(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "$1$2");
  value = value.replace(/(\d{3})(\d{1,3})$/, "$1-$2");
  return value;
}

export function currencyString(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  return value;
}

export function cpfString(value: string) {
  let newVal = value;
  if (!newVal.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    newVal = newVal.replace(/\D/g, "");
    newVal = newVal.replace(/(\d{3})(\d)/, "$1.$2");
    newVal = newVal.replace(/(\d{3})(\d)/, "$1.$2");
    newVal = newVal.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return newVal;
  }
  return newVal;
}

export function cnpjString(value: string) {
  if (!value.match(/^(\d{2}).(\d{3}).(\d{3})\/\/(\d{4})-(\d{2})$/)) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1/$2");
    value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    return value;
  }
  return value;
}

export function phoneString(value: string) {
  if (!value.match(/^(\d{2})(\d{4})-(\d{3})$/)) {
    value = value.replace(/\D/g, "");

    value = value.replace(/(\d{2})(\d)/, "($1) $2");
    value = value.replace(/(\d{4})(\d)/, "$1-$2");
    value = value.replace(/(\d{4})(\d)$/, "$1-$2");
    return value;
  }
  return value;
}

export function mobileString(value: string) {
  if (!value.match(/^(\d{2}) (\d{1}) (\d{4})-(\d{4})$/)) {
    value = value.replace(/\D/g, "");

    value = value.replace(/(\d{2})(\d{1})(\d)/, "($1) $2 $3");
    value = value.replace(/(\d{4})(\d)/, "$1-$2");
    value = value.replace(/(\d{4})(\d)$/, "$1-$2-$3");
    return value;
  }
  return value;
}
export function dateString(value: string) {
  if (value.indexOf("-") === 4) {
    value = DateTime.fromISO(value).toFormat("dd/LL/yyyy");
    return value;
  }
  // Remove tudo que não é número
  value = value.replace(/\D/g, "");
  // Adiciona a máscara
  if (value.length <= 2) {
    value = value.replace(/(\d{2})/, "$1");
  } else if (value.length <= 4) {
    value = value.replace(/(\d{2})(\d{2})/, "$1/$2");
  } else if (value.length <= 8) {
    value = value.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
  } else {
    value = value.substring(0, 8).replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
  }

  return value;
}
export function dateTime(value: string) {
  if (value.indexOf("-") === 4) {
    value = DateTime.fromISO(value).toFormat("dd/LL/yyyy HH:mm");
    return value;
  }
  // Remove tudo que não é número
  value = value.replace(/\D/g, "");
  // Adiciona a máscara
  if (value.length <= 2) {
    value = value.replace(/(\d{2})/, "$1");
  } else if (value.length <= 4) {
    value = value.replace(/(\d{2})(\d{2})/, "$1/$2");
  } else if (value.length <= 8) {
    value = value.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
  } else if (value.length <= 10) {
    value = value.replace(/(\d{2})(\d{2})(\d{4})(\d{2})/, "$1/$2/$3 $4");
  } else if (value.length <= 12) {
    value = value.replace(
      /(\d{2})(\d{2})(\d{4})(\d{2})(\d{2})/,
      "$1/$2/$3 $4:$5"
    );
  } else {
    value = value
      .substring(0, 12)
      .replace(/(\d{2})(\d{2})(\d{4})(\d{2})(\d{2})/, "$1/$2/$3 $4:$5");
  }
  return value;
}

export function time(value: string) {
  // Remove tudo que não é número
  value = value.replace(/\D/g, "");

  // Adiciona a máscara
  if (value.length <= 2) {
    value = value.replace(/(\d{2})/, "$1");
  } else if (value.length <= 4) {
    value = value.replace(/(\d{2})(\d{2})/, "$1:$2");
  } else {
    value = value.substring(0, 4).replace(/(\d{2})(\d{2})/, "$1:$2");
  }

  return value;
}

export function hex(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 7;
  let value = e.currentTarget.value;

  value = value.replace(/[^#A-Fa-f0-9]/g, "");

  if (value.length > 0 && value[0] !== "#") {
    value = "#" + value;
  }

  if (value.length > 7) {
    value = value.slice(0, 7);
  }

  e.currentTarget.value = value;

  return e;
}

export const warnValidation = (errors: any, formRef: FormHandles | null) => {
  const validationErrors: any = {};
  if (errors instanceof Yup.ValidationError) {
    errors.inner.forEach((e) => {
      validationErrors[e.path as any] = e.message;
    });
    formRef?.setErrors(validationErrors);
  }
};
