import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  useDisclosure,
  Button,
  Select,
  Input,
  Text,
} from "@chakra-ui/react";
import TableWithPagination from "../../components/TableWithPagination";
import Header from "../../components/Header/Header";
import { getPurchaseOrders } from "../../services/purchaseOrderService";
import { Tag } from "../../models/Filter";
import Filter from "../../components/Filter";
import { useMediaQuery } from "react-responsive";
import PurchaseOrderCard from "./components/PurchaseOrderCard";
import FilterModal from "../../components/FilterModal";
import { FaPlus } from "react-icons/fa";
import RedirectModal from "./components/RedirectModal";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const PurchaseOrders = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [status, setStatus] = useState<boolean>();
  const [tags, setTags] = useState<Tag[]>([]);
  const [page, setPage] = useState<any[]>([]);
  const [poNumber, setPoNumber] = useState<string>("");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenRedirect,
    onOpen: onOpenRedirect,
    onClose: onCloseRedirect,
  } = useDisclosure();
  const navigate = useNavigate();

  const get = async (
    localPageSize?: number,
    localPageNumber?: number,
    sortField?: string,
    sortDirection?: string
  ) => {
    const localPs = localPageSize ?? pageSize;
    const localPn = localPageNumber ?? pageIndex;

    const response = await getPurchaseOrders({
      pageSize: localPs,
      pageNumber: localPn,
      sortField,
      sortDirection,
      status,
      poNumber: tags
        .filter((t) => t.type === "poNumber")
        ?.map((t) => t.value)
        .join("|"),
      ncm: tags
        .filter((t) => t.type === "ncm")
        ?.map((t) => t.value)
        .join("|"),
      cvfRef: tags
        .filter((t) => t.type === "cvfRef")
        ?.map((t) => t.value)
        .join("|"),
      dischargePort: tags
        .filter((t) => t.type === "dischargePort")
        ?.map((t) => t.value)
        .join("|"),
      companyId: tags
        .filter((t) => t.type === "companyId")
        ?.map((t) => t.value)
        .join("|"),
      countryOfOrigin: tags
        .filter((t) => t.type === "origin")
        ?.map((t) => t.value)
        .join("|"),
      exporter: tags
        .filter((t) => t.type === "supplier")
        ?.map((t) => t.value)
        .join("|"),
    });
    setPage(response.data);
    return response;
  };
  useEffect(() => {
    get();
  }, [tags, status]);

  return (
    <Box height="100%" overflowX="hidden">
      <Header />
      <Flex
        height="100%"
        flexDirection="column"
        alignItems="center"
        backgroundColor="var(--main-bg-color)"
      >
        <Flex width="90%">
          <Heading size="lg" mt="48px" mb="24px" fontWeight="normal">
            Ordens de compra
          </Heading>
        </Flex>
        <FilterModal
          {...{
            isOpen,
            onClose,
            tags,
            setTags,
          }}
        />
        <Box
          p="32px"
          w="90%"
          backgroundColor="var(--filter-color)"
          borderRadius="8px"
        >
          {isMobile ? (
            <Flex w="100%" justifyContent="center">
              <Button
                w="80%"
                backgroundColor="var(--icon-color)"
                color="white"
                onClick={onOpen}
              >
                Filtrar documentos
              </Button>
            </Flex>
          ) : (
            <Flex width="90%">
              <Filter
                extraFilters={[
                  <Box>
                    <Heading size="sm" mb="8px">
                      Status
                    </Heading>
                    <Select
                      onChange={(e) =>
                        !e.target.value
                          ? setStatus(undefined)
                          : setStatus(e.target.value === "true")
                      }
                      backgroundColor="white"
                      width={isMobile ? "80vw" : "16vw"}
                    >
                      <option value={undefined}></option>
                      <option value={"false"}>Pendentes</option>
                      <option value={"true"}>Assinados</option>
                    </Select>
                  </Box>,
                  <Box>
                    <Heading size="sm" mb="8px">
                      Número da Ordem
                    </Heading>
                    <Input
                      backgroundColor={"white"}
                      w={{ base: "100%", md: "16vw" }}
                      name="value"
                      value={poNumber}
                      onChange={(e) => setPoNumber(e.target.value)}
                      onKeyUp={(e) => {
                        if (
                          e.key === "Enter" &&
                          poNumber.length > 0 &&
                          !tags.some(
                            (t) =>
                              t.label === "poNumber" && t.value === poNumber
                          )
                        ) {
                          setTags((tags: Tag[]) => [
                            ...tags,
                            {
                              type: "poNumber",
                              label: "Número da ordem",
                              value: poNumber,
                            },
                          ]);
                        }
                      }}
                      type="number"
                    />
                    <Flex
                      flexWrap="wrap"
                      gap="12px"
                      maxHeight="130px"
                      overflowY={
                        `${tags.map((t) => t.label).join("")}${tags
                          .map((t) => t.value)
                          .join("")}}`.length > 60
                          ? "scroll"
                          : "hidden"
                      }
                      marginY="24px"
                    >
                      {tags
                        .filter((t) => t.type === "poNumber")
                        .map((tag) => (
                          <Flex
                            backgroundColor="var(--icon-color)"
                            borderColor="#5A96E3"
                            borderRadius="3px"
                            borderWidth="1px"
                            height="32px"
                            padding="4px 8px"
                            color="white"
                            alignItems="center"
                            gap="8px"
                            cursor="pointer"
                            onClick={() =>
                              setTags((tags: any[]) => {
                                const newTags = tags.map((t) => t);
                                newTags.splice(
                                  tags.findIndex(
                                    (t) =>
                                      t.label === tag.label &&
                                      t.value === tag.value
                                  ),
                                  1
                                );
                                return newTags;
                              })
                            }
                          >
                            <Text fontWeight="semibold">{tag.value}</Text>
                            <FontAwesomeIcon icon={faClose} color="white" />
                          </Flex>
                        ))}
                    </Flex>
                  </Box>,
                ]}
                {...{
                  tags,
                  setTags,
                }}
              />
            </Flex>
          )}
        </Box>
        <Flex w="90%" mt="32px" justifyContent="flex-end" gap="16px">
          <Button
            color="white"
            borderRadius="4px"
            backgroundColor="var(--icon-color)"
            onClick={() => navigate("/savePurchaseOrders")}
          >
            <Flex justifyContent="center" alignItems="center" gap="8px">
              <FaPlus color="white" /> Adicionar PO
            </Flex>
          </Button>
        </Flex>
        <Flex width="90%" flexDir="column" mt="16px" gap="24px">
          {page.length === 0 && (
            <Flex
              justifyContent="center"
              alignItems="center"
              backgroundColor="white"
              p="64px"
            >
              <Heading fontWeight="normal" size="md">
                Ainda não há Ordens de Compra cadastradas.
              </Heading>
            </Flex>
          )}
          {page.map((p) => (
            <PurchaseOrderCard get={get} {...{ p }} onOpen={onOpenRedirect} />
          ))}
        </Flex>
        <RedirectModal onClose={onCloseRedirect} isOpen={isOpenRedirect} />
        <Flex width="90%" my="32px">
          <TableWithPagination
            hideTable
            columns={[]}
            {...{
              tags,
              page,
              setPage,
              get,
              pageIndex,
              setPageIndex,
              pageSize,
              setPageSize,
            }}
            emptyMessage="Não há processos ainda"
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default PurchaseOrders;
