import {
  Button,
  CloseButton,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import DocumentUpload from "../../../../../components/DocumentUpload";
import { useRef, useState } from "react";
import { importCompanies } from "../../../../../services/companyService";
import { importSuppliers } from "../../../../../services/supplierService";

const ImportModal = ({
  isOpen,
  onClose,
  getSavedCompanies,
  isSupplier = false,
}: {
  isOpen: boolean;
  onClose: () => void;
  getSavedCompanies: () => void;
  isSupplier?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const inputRef = useRef(null);
  const toast = useToast();

  const startMigration = async () => {
    setLoading(true);
    if (file) {
      const response = isSupplier
        ? await importSuppliers(file)
        : await importCompanies(file);
      if (response) {
        getSavedCompanies();
        toast({
          title: "Importação realizada.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      }
    }
    setLoading(false);
  };

  return (
    <Modal isCentered {...{ isOpen, onClose }} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="space-between">
            <Heading size="md" fontWeight="normal" mb="8px">
              Importar
            </Heading>
            <CloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Text mb="24px">
            {isSupplier
              ? `O excel deve ter uma coluna com o exato seguinte nome: "Nome"`
              : `O excel deve ter duas colunas com os exatos seguintes nomes: "Nome"
            e "Cnpj"`}
          </Text>
          <DocumentUpload
            inputRef={inputRef}
            file={file}
            setFile={setFile}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onFileChange={(e) => setFile(e)}
            w="100%"
            py="20px"
          />
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="flex-end">
            <Button
              isLoading={loading}
              isDisabled={!file}
              marginTop="16px"
              backgroundColor="var(--icon-color)"
              color="white"
              borderRadius="3px"
              onClick={() => startMigration()}
            >
              Importar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImportModal;
