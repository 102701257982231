import {
  Badge,
  Box,
  CloseButton,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { PurchaseOrderViewModel } from "../../../models/PurchaseOrder";
import { signPurchaseOrder } from "../../../services/purchaseOrderService";
import { useState } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const PurchaseOrderModal = ({
  p,
  get,
  isOpen,
  onClose,
}: {
  p: PurchaseOrderViewModel;
  get: () => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const auth = useAuthUser<any>();

  const formatterUsd = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "USD",
  });
  const handleSign = async (position: string) => {
    try {
      setLoading(true);
      const response = await signPurchaseOrder(p.id, position);
      if (response) {
        await get();
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const line = (title: string, value: any) => (
    <Flex flexDir="column" gap="0px" w={{ base: "200px", md: "300px" }}>
      <Heading size="sm" fontWeight="semibold">
        {title}:
      </Heading>

      <Heading fontWeight="normal" size="md">
        {value}
      </Heading>
    </Flex>
  );

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent fontFamily="Inter">
        <ModalHeader>
          <Flex justifyContent="space-between">
            <Heading size="lg" fontWeight="semibold">
              Alfa {p?.process.cvfRef} / PO {p.poNumber}
            </Heading>
            <CloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex flexDir="column">
            <Flex
              flexDir="column"
              gap="16px"
              backgroundColor="white"
              p="16px"
              pt="0"
            >
              <Heading size="md">Parcelas</Heading>
              <Flex>
                <Flex
                  flexDir="column"
                  gap="0px"
                  w={{ base: "200px", md: "300px" }}
                >
                  <Heading size="sm" fontWeight="semibold">
                    Termo de pagamento:
                  </Heading>

                  <Heading fontWeight="normal" size="md">
                    {p?.condition}
                  </Heading>
                </Flex>
                <Flex flexDir="column" gap="0px">
                  <Heading size="sm" fontWeight="semibold">
                    Total pago:
                  </Heading>

                  <Heading fontWeight="normal" size="md" color="red.600">
                    {formatterUsd.format(p?.totalPayed ?? 0)}
                  </Heading>
                </Flex>
              </Flex>
              <Flex>
                <Flex
                  flexDir="column"
                  gap="0px"
                  w={{ base: "200px", md: "300px" }}
                >
                  <Heading size="sm" fontWeight="semibold">
                    Total a pagar:
                  </Heading>

                  <Heading size="md" color="green.600" fontWeight="normal">
                    {formatterUsd.format(p?.totalToPay ?? 0)}
                  </Heading>
                </Flex>
                <Flex flexDir="column" gap="0px">
                  <Heading size="sm" fontWeight="semibold">
                    Total do processo:
                  </Heading>
                  <Heading size="md" fontWeight="normal">
                    {p?.process.totalInvoice}
                  </Heading>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap="16px" p="16px">
              {p?.process.financialItems
                .filter((f) => (f.valorPagamento?.length ?? 0) > 1)
                .map((f, index) => (
                  <>
                    <Flex>
                      <Flex
                        flexDir="column"
                        gap="0px"
                        w={{ base: "200px", md: "300px" }}
                      >
                        <Heading size="sm" fontWeight="semibold">
                          Taxa de câmbio ({index === 0 ? "Sinal" : "Saldo"})
                        </Heading>
                        <Flex flexDir="column" gap="16px">
                          <Heading size="md" fontWeight="normal">
                            {(f.taxaCambio?.length ?? 0) > 0
                              ? f.taxaCambio
                              : "Por definir"}
                          </Heading>
                        </Flex>
                      </Flex>
                      <Flex flexDir="column" gap="0px">
                        <Heading size="sm" fontWeight="semibold">
                          Data de câmbio
                        </Heading>
                        <Flex flexDir="column" gap="16px">
                          <Heading size="md" fontWeight="normal">
                            {f.dataCambio
                              ? DateTime.fromISO(f.dataCambio).toFormat(
                                  "dd/LL/yyyy"
                                )
                              : f.dataPagamento
                              ? DateTime.fromISO(f.dataPagamento).toFormat(
                                  "dd/LL/yyyy"
                                )
                              : "Por definir"}
                          </Heading>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex>
                      <Flex
                        flexDir="column"
                        gap="0px"
                        w={{ base: "200px", md: "300px" }}
                      >
                        <Heading size="sm" fontWeight="semibold">
                          {index === 0 ? "Sinal" : "Saldo"} de pagamento
                        </Heading>
                        <Flex flexDir="column" gap="16px">
                          <Heading size="md" fontWeight="normal">
                            {f.valorPagamento}
                          </Heading>
                        </Flex>
                      </Flex>
                      <Flex flexDir="column" gap="4px">
                        <Heading size="sm" fontWeight="semibold">
                          Status
                        </Heading>
                        <Flex flexDir="column" gap="16px">
                          <Heading
                            size="sm"
                            fontWeight="normal"
                            borderWidth="2px"
                            p="12px"
                            backgroundColor={
                              f.dataPagamento ? "#F1FCF5" : "#F2F8FD"
                            }
                            borderColor={
                              f.dataPagamento ? "#8BEAB2" : "#8FC5EA"
                            }
                            color={f.dataPagamento ? "#27AE60" : "#2980B9"}
                          >
                            {f.dataPagamento ? "PAGO" : "PROGRAMADO"}
                          </Heading>
                        </Flex>
                      </Flex>
                    </Flex>
                  </>
                ))}
            </Flex>
            <Flex p="16px" pb="32px" flexDir="column" gap="16px">
              <Heading size="md">Demais detalhes</Heading>
              {line("Razão social", p.corporateReason)}
              {line("CNPJ", p.cnpj)}
              {line("Invoice fornecedor", p.invoice)}
              {line(
                "Data do pedido",
                DateTime.fromISO(p.createdDate).toFormat("dd/LL/yyyy")
              )}
              {line("Fornecedor", p.supplier)}
              {line("NCM", p.ncm)}
              {line("Tipo de importação", "encomenda")}
              {line("Incoterm", p.incoterm)}
              {line("Classificação", p.category)}
              {line("Qte container", p.containerQuantity)}
              {line("Cond. de pgto", p.condition)}
              {line("Valor fob total", p.payment)}
              {line("Margem", p.margin)}
              {line("Observação", p.observation)}
              <Heading size="sm" fontWeight="semibold">
                Status:
              </Heading>
              <Flex p="16px" flexDir="column" gap="12px">
                <Box>
                  <Text fontSize="12px">CVF Distribuidora:</Text>
                  <Badge
                    onClick={() =>
                      auth.position === 2 && handleSign("Administration")
                    }
                    cursor="pointer"
                    colorScheme={p.administrationSigned ? "green" : "yellow"}
                    p="8px"
                  >
                    {loading ? (
                      <Spinner />
                    ) : p.administrationSigned ? (
                      <>
                        Assinado em{" "}
                        {DateTime.fromISO(
                          p.administrationSignedDate ?? ""
                        ).toFormat("dd/LL/yyyy")}
                        , por {p.administrationSignedBy}
                      </>
                    ) : (
                      "Pendente"
                    )}
                  </Badge>
                </Box>

                <Box>
                  <Text fontSize="12px">Comercial:</Text>
                  <Badge
                    onClick={() =>
                      (auth.position === 2 || auth.position === 4) &&
                      handleSign("Commercial")
                    }
                    cursor="pointer"
                    colorScheme={p.commercialSigned ? "green" : "yellow"}
                    p="8px"
                  >
                    {loading ? (
                      <Spinner />
                    ) : p.commercialSigned ? (
                      <>
                        Assinado em{" "}
                        {DateTime.fromISO(
                          p.commercialSignedDate ?? ""
                        ).toFormat("dd/LL/yyyy")}
                        , por {p.commercialSignedBy}
                      </>
                    ) : (
                      "Pendente"
                    )}
                  </Badge>
                </Box>

                <Box>
                  <Text fontSize="12px">Financeiro:</Text>
                  <Badge
                    onClick={() =>
                      (auth.position === 2 || auth.position === 5) &&
                      handleSign("Financial")
                    }
                    cursor="pointer"
                    colorScheme={p.financialSigned ? "green" : "yellow"}
                    p="8px"
                  >
                    {loading ? (
                      <Spinner />
                    ) : p.financialSigned ? (
                      <>
                        Assinado em{" "}
                        {DateTime.fromISO(p.financialSignedDate ?? "").toFormat(
                          "dd/LL/yyyy"
                        )}
                        , por {p.financialSignedBy}
                      </>
                    ) : (
                      "Pendente"
                    )}
                  </Badge>
                </Box>

                <Box>
                  <Text fontSize="12px">Operacional:</Text>
                  <Badge
                    onClick={() =>
                      (auth.position === 2 || auth.position === 3) &&
                      handleSign("Operational")
                    }
                    cursor="pointer"
                    colorScheme={p.operationalSigned ? "green" : "yellow"}
                    p="8px"
                  >
                    {loading ? (
                      <Spinner />
                    ) : p.operationalSigned ? (
                      <>
                        Assinado em{" "}
                        {DateTime.fromISO(
                          p.operationalSignedDate ?? ""
                        ).toFormat("dd/LL/yyyy")}
                        , por {p.operationalSignedBy}
                      </>
                    ) : (
                      "Pendente"
                    )}
                  </Badge>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default PurchaseOrderModal;
