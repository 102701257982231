import { CompanyFormData } from "../pages/Configurations/tabs/types";
import api from "./api";

export const saveCompany = async (data: { name: string; cnpj: string }) => {
  try {
    const response = await api.post(`/api/company`, data);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};
export const importCompanies = async (file: File) => {
  try {
    let formData = new FormData();
    if (file) {
      formData.append("file", new File([file], file.name));
    }
    const response = await api.post("/api/company/import", formData);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (err) {}
};
export const updateCompany = async (data: CompanyFormData) => {
  try {
    const response = await api.put(`/api/company/${data.id}`, data);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const getCompanies = async (withCnpj: boolean = false) => {
  try {
    const response = await api.get(`/api/company?withCnpj=${withCnpj}`);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};
export const getCompany = async (id: string) => {
  try {
    const response = await api.get(`/api/company/${id}`);
    if (!response.data.success) {
      console.log("error", response.data);
    }
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const addPermission = async ({
  permissionId,
  companyId,
}: {
  permissionId: string;
  companyId: string;
}) => {
  try {
    const response = await api.put(`/api/company/${companyId}/AddPermission`, {
      permissionId,
    });
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};
export const removePermission = async ({
  permissionId,
  companyId,
}: {
  permissionId: string;
  companyId: string;
}) => {
  try {
    const response = await api.put(
      `/api/company/${companyId}/RemovePermission`,
      {
        permissionId,
      }
    );
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteCompany = async (companyId: string) => {
  try {
    const response = await api.delete(`/api/company/${companyId}`);
    if (!response.data.success) {
      console.log("error", response.data.notifications);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
};
