import { useEffect, useState } from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import AddUsers from "./tabs/AddUsers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faHandshake, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Header from "../../components/Header/Header";
import { useMediaQuery } from "react-responsive";
import {
  FaAngleLeft,
  FaKey,
  FaTruckLoading,
  FaUser,
  FaUserAlt,
} from "react-icons/fa";
import Emulation from "./tabs/Emulation";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import CompaniesList from "./tabs/Company/CompaniesList";
import AddSuppliers from "./tabs/AddSuppliers";
import Permissions from "./tabs/Permissions";
import CompanyPermissions from "./tabs/CompanyPermissions";

const Configurations = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const auth = useAuthUser<any>();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<number | undefined>(undefined);
  const [tabs, setTabs] = useState([
    {
      label: "Emular clientes",
      icon: (
        <FaUserAlt color="var(--icon-color)" fontSize={isMobile ? 18 : 24} />
      ),
      content: <Emulation />,
    },
    {
      label: "Adicionar usuário",
      icon: (
        <FontAwesomeIcon
          color="var(--icon-color)"
          icon={faUserPlus}
          fontSize={isMobile ? 18 : 24}
        />
      ),
      content: <AddUsers />,
    },
    {
      label: "Gerenciar clientes",
      icon: (
        <FontAwesomeIcon
          color="var(--icon-color)"
          icon={faHandshake}
          fontSize={isMobile ? 18 : 24}
        />
      ),
      content: <CompaniesList />,
    },
    {
      label: "Adicionar fornecedores",
      icon: (
        <FaTruckLoading
          color="var(--icon-color)"
          fontSize={isMobile ? 18 : 24}
        />
      ),
      content: <AddSuppliers />,
    },
    {
      label: "Gerenciar permissões",
      icon: <FaKey color="var(--icon-color)" fontSize={isMobile ? 18 : 24} />,
      content: <Permissions />,
    },

    {
      label: "Gerenciar permissões das empresas",
      icon: (
        <Flex color="var(--icon-color)">
          <FaKey fontSize={isMobile ? 18 : 24} />
          <FaUser fontSize={isMobile ? 18 : 24} />
        </Flex>
      ),
      content: <CompanyPermissions />,
    },
  ]);

  useEffect(() => {
    if (!auth?.admin) navigate("/");
  }, [auth]);

  return (
    <Box height={isMobile ? undefined : "100vh"} overflow="hidden">
      <Header />
      <Box
        height="95vh"
        overflowY="auto"
        padding="8px"
        backgroundColor="var(--main-bg-color)"
      >
        <Flex
          gap="8px"
          width="90%"
          cursor="pointer"
          mt="32px"
          ml="32px"
          alignItems="center"
          onClick={() =>
            isMobile
              ? currentTab === undefined
                ? navigate(-1)
                : setCurrentTab(undefined)
              : navigate(-1)
          }
        >
          <FaAngleLeft />
          <Heading fontWeight="normal" size="md">
            Voltar
          </Heading>
        </Flex>
        <Flex justifyContent="center">
          <Flex
            w={currentTab === undefined ? "45%" : "65%"}
            padding="32px"
            gap="32px"
            marginTop="32px"
            background="white"
          >
            {currentTab === undefined && (
              <Box width={"100%"}>
                {tabs.map((tab, index) => (
                  <Flex
                    key={index}
                    backgroundColor={
                      index === currentTab ? "var(--main-bg-color)" : undefined
                    }
                    borderBottomWidth={index === currentTab ? "3px" : undefined}
                    borderBottomColor={
                      index === currentTab ? "var(--icon-color)" : undefined
                    }
                    _hover={{
                      backgroundColor:
                        index === currentTab
                          ? "var(--main-bg-color)"
                          : "var(--hover-color)",
                    }}
                    cursor="pointer"
                    padding="16px 8px"
                    gap="16px"
                    paddingLeft={isMobile ? 0 : "32px"}
                    marginRight={isMobile ? 0 : "16px"}
                    alignItems="center"
                    borderRadius="8px"
                    onClick={() => setCurrentTab(index)}
                  >
                    {tab.icon}
                    <Text
                      maxWidth="400px"
                      noOfLines={1}
                      overflow="hidden"
                      fontSize={isMobile ? "18px" : "24px"}
                    >
                      {tab.label}
                    </Text>
                  </Flex>
                ))}
              </Box>
            )}
            {currentTab != undefined && (
              <Box p="32px" width="100%">
                <Flex
                  gap="8px"
                  width="90%"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => setCurrentTab(undefined)}
                  mb="32px"
                >
                  <FaAngleLeft />
                  <Heading fontWeight="normal" size="md">
                    Voltar para o menu
                  </Heading>
                </Flex>
                {tabs[currentTab].content}
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
export default Configurations;
