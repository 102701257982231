import { Box, Flex, Heading, ResponsiveValue, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const Card = ({
  option,
  button = false,
}: {
  button?: boolean;
  option: {
    title: string;
    icon?: any;
    link: any;
    position?: ResponsiveValue<any>;
  };
}) => {
  const navigate = useNavigate();

  return (
    <Flex
      flexDirection="column"
      cursor="pointer"
      width="288px"
      height="197px"
      overflow="hidden"
      justifyContent="center"
      backgroundColor="white"
      alignItems="center"
      borderRadius="6px"
      color="var(--icon-color)"
      _hover={{
        bottom: "5px",
        backgroundColor: "var(--icon-color)",
        color: "white",
      }}
      onClick={() => (button ? option.link() : navigate(option.link))}
    >
      {option.icon && (
        <Box fontSize="40px" className="material-symbols-outlined">
          {option.icon}
        </Box>
      )}
      <Text
        marginTop={option.icon ? "16px" : undefined}
        textAlign="center"
        fontSize="29px"
        letterSpacing="0px"
      >
        {option.title}
      </Text>
    </Flex>
  );
};

export default Card;
