import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Progress,
  Select,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import {
  removeTracking,
  saveTracking,
  updateTracking,
} from "../../../services/trackingService";
import { CalendarIcon } from "@chakra-ui/icons";
import { FieldValue, useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { formatDateTime } from "../../../services/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { processStatus } from "../../Configurations/tabs/types";
import { FaCheck } from "react-icons/fa";

const EditingTrack = ({
  d,
  processId,
  get,
  setEditing,
}: {
  d: any;
  processId?: string;
  get?: () => void;
  setEditing: Dispatch<SetStateAction<boolean>>;
}) => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState<string>(d.status);
  const [selectedDate, setSelectedDate] = useState(new Date(d.dateTime));
  const [dateValue, setDateValue] = useState(
    DateTime.fromISO(d.dateTime).toFormat("dd/LL/yyyy HH:mm")
  );

  const validateRequired = (value: FieldValue<Record<string, any>>) =>
    !value || /^\s*$/.test(value) ? "Esse campo é obrigatório." : undefined;
  const save = async () => {
    setLoading(true);
    const body = {
      id: d.id,
      processId: processId ?? "",
      status: status,
      dateTime: selectedDate,
    };
    const response = d.id
      ? await updateTracking(body as any)
      : await saveTracking(body as any);
    if (response) {
      if (get) get();
      setEditing(false);
    }
    setLoading(false);
  };
  const remove = async () => {
    setLoading(true);
    const response = await removeTracking(d.id);
    if (response) {
      if (get) get();
      onClose();
    }

    setLoading(false);
  };
  const { register, handleSubmit, formState } = useForm();

  return (
    <Flex gap="12px">
      <Box>
        <Heading size="sm" marginBottom="8px">
          Status
        </Heading>
        <Select value={status} onChange={(e) => setStatus(e.target.value)}>
          {processStatus.map((p) => (
            <option key={p.value} value={p.value}>
              {p.label}
            </option>
          ))}
        </Select>
      </Box>
      <Box>
        <Heading size="sm" marginBottom="8px">
          Data
        </Heading>
        <DatePicker
          locale="pt-br"
          dateFormat="dd/MM/yyyy HH:mm"
          showTimeSelect
          timeFormat="HH:mm"
          timeCaption="Hora"
          popperPlacement="bottom-start"
          selected={selectedDate}
          onChange={(date: Date, event) => {
            if (!event)
              setDateValue(
                `${date.toLocaleDateString("pt")} ${date
                  .toLocaleTimeString("pt")
                  .substring(0, 5)}`
              );
            setSelectedDate(date);
          }}
          onSelect={(date: Date) => {
            setDateValue(
              `${date.toLocaleDateString("pt")} ${date
                .toLocaleTimeString("pt")
                .substring(0, 5)}`
            );
            setSelectedDate(date);
          }}
          customInput={
            <InputGroup>
              <MaskedTextInput
                type="text"
                className="styled-input"
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  " ",
                  /\d/,
                  /\d/,
                  ":",
                  /\d/,
                  /\d/,
                ]}
                width="30vh"
                value={dateValue}
                {...register("dateTime", {
                  onChange: (e: any) =>
                    setDateValue(formatDateTime(dateValue, e.target.value)),
                  validate: (value: FieldValue<Record<string, any>>) =>
                    validateRequired(dateValue),
                })}
              />
              <InputRightElement>
                <CalendarIcon />
              </InputRightElement>
            </InputGroup>
          }
        />
        {formState.errors.dateTime && (
          <div style={{ color: "red", marginBottom: "16px" }}>
            {typeof formState.errors.dateTime.message === "string" &&
              formState.errors.dateTime.message}
          </div>
        )}
      </Box>
      <Box>
        <Heading size="sm" marginBottom="8px" color="white">
          a
        </Heading>
        <Flex gap="4px">
          <IconButton
            isLoading={loading}
            backgroundColor="var(--icon-color)"
            color="white"
            position="relative"
            aria-label="Salvar"
            icon={<FaCheck />}
            onClick={() => save()}
          >
            Salvar
          </IconButton>
          <Popover
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            placement="top"
          >
            <PopoverTrigger>
              <IconButton
                isLoading={loading}
                borderColor="var(--icon-color)"
                color="var(--icon-color)"
                borderWidth="1px"
                backgroundColor="transparent"
                position="relative"
                aria-label="Deletar"
                icon={<FontAwesomeIcon icon={faTrash} />}
              >
                Deletar
              </IconButton>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverHeader fontWeight="semibold">Confirmação</PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>Tem certeza que deseja excluir?</PopoverBody>
              <PopoverFooter display="flex" justifyContent="flex-end">
                <ButtonGroup size="sm">
                  <Button
                    isLoading={loading}
                    variant="outline"
                    onClick={() => onClose()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => {
                      remove();
                    }}
                    isLoading={loading}
                    colorScheme="red"
                    position="relative"
                    aria-label="Deletar"
                  >
                    Excluir
                  </Button>
                </ButtonGroup>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </Flex>
      </Box>
    </Flex>
  );
};

export default EditingTrack;
