import { Box, Flex, Heading, Select, Text } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { companyOptions, useCompany } from "../../contexts/CompanyContext";

const HeaderOption = ({ o, style, select }: any) => {
  const navigate = useNavigate();
  const { currentCompany, setCurrentCompany } = useCompany();
  const location = useLocation();
  const activeLink =
    location.pathname.includes(o.link) ||
    (location.pathname.includes("process") && o.link?.includes("process"));
  return (
    <Flex
      height="100%"
      onClick={select ? undefined : () => navigate(o.link)}
      backgroundColor={activeLink ? "var(--main-bg-color)" : undefined}
      borderBottomWidth={activeLink ? "3px" : undefined}
      borderBottomColor={activeLink ? "var(--icon-color)" : undefined}
      _hover={{
        backgroundColor: activeLink
          ? "var(--main-bg-color)"
          : "var(--hover-color)",
      }}
      cursor="pointer"
      padding={select ? undefined : "12px 32px"}
      style={{ ...style }}
      alignItems="center"
      gap="8px"
      fontWeight="semibold"
    >
      {select ? (
        <Select
          value={currentCompany}
          onChange={(e) => setCurrentCompany(parseFloat(e.target.value))}
          w="180px"
          height="24px"
          border="none"
        >
          {companyOptions.map((c) => (
            <option key={c.key} value={c.value}>
              {c.label}
            </option>
          ))}
        </Select>
      ) : (
        <>
          <Box
            fontWeight="semibold"
            color="var(--icon-color)"
            fontSize="16px"
            className="material-symbols-outlined"
          >
            {o.icon}
          </Box>
          <Text
            color={activeLink ? "var(--icon-color)" : undefined}
            textAlign="center"
            fontSize="17px"
            letterSpacing="0px"
          >
            {o.title}
          </Text>
        </>
      )}{" "}
    </Flex>
  );
};
export default HeaderOption;
