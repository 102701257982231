import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Flex, Text, Select, TableContainer } from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import LoadingTable from "./LoadingTable";
import Pagination from "@mui/material/Pagination";
import Table from "./Table";

const TableWithPagination = ({
  columns,
  tags,
  get,
  emptyMessage,
  nestedColumns,
  pageIndex,
  setPageIndex,
  pageSize,
  setPageSize,
  page,
  financial = false,
  hideTable = false,
  loading,
  setPage,
}: {
  hideTable?: boolean;
  columns: {
    label: string;
    index?: string;
    data: any;
    width?: string[] | string | number;
    sortable?: boolean;
  }[];
  nestedColumns?: {
    label: string;
    index?: string;
    data: any;
    width?: string[] | string | number;
  }[];
  financial?: boolean;
  tags: any;
  get: (
    pageSize?: number,
    pageIndex?: number,
    sortField?: string,
    sortDirection?: string
  ) => Promise<any>;
  pageIndex: number;
  setPageIndex: Dispatch<SetStateAction<number>>;
  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
  emptyMessage: string;
  loading?: boolean;
  page: any[];
  setPage: Dispatch<SetStateAction<any[]>>;
}) => {
  const [pageCount, setPageCount] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [localLoading, setLocalLoading] = useState(true);
  const [sortField, setSortField] = useState<string>();
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  // Updated to include sorting parameters
  const gotoPage = async (
    index: number,
    localPageSize?: number,
    field?: string,
    direction?: "asc" | "desc"
  ) => {
    setLocalLoading(true);
    setPageIndex(index);
    const localPs = localPageSize ?? pageSize;

    // Pass sort parameters to the get function
    const sortFieldToUse = field ?? sortField;
    const sortDirectionToUse = direction ?? sortDirection;

    const response = await get(
      localPs,
      index,
      sortFieldToUse,
      sortDirectionToUse
    );
    setPage(response.data ?? response);
    recalculateTable(response.totalCount, localPs, index);
    setLocalLoading(false);
  };

  const handleSort = (field: string, direction: "asc" | "desc") => {
    setSortField(field);
    setSortDirection(direction);
    // Fetch data with new sort parameters, but stay on current page
    gotoPage(0, pageSize, field, direction);
  };

  const recalculateTable = async (
    total: number,
    localPageSize?: number,
    index?: number
  ) => {
    const currentPageSize = localPageSize ?? pageSize;
    const defaultPageCount =
      Math.floor(total / currentPageSize) +
      (total % currentPageSize > 0 ? 1 : 0);
    setPageCount(defaultPageCount);
    setTotalCount(total);
  };

  useEffect(() => {
    gotoPage(0);
  }, [tags]);

  return localLoading || loading ? (
    <LoadingTable />
  ) : (
    <>
      {!hideTable && (
        <TableContainer>
          {/* Use the original Table component with the sortable header capability */}
          <Table
            sortField={sortField}
            setSortField={setSortField}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            onSort={handleSort}
            columns={columns.map((col) => ({
              ...col,
              onHeaderClick: col.sortable
                ? () =>
                    handleSort(
                      col.data,
                      sortField === col.data && sortDirection === "asc"
                        ? "desc"
                        : "asc"
                    )
                : undefined,
              isSorted: sortField === col.data,
              isSortedDesc: sortField === col.data && sortDirection === "desc",
            }))}
            nestedColumns={nestedColumns}
            page={page}
            financial={financial}
            emptyMessage={emptyMessage}
          />
        </TableContainer>
      )}
      <Flex
        w="100%"
        justifyContent="space-between"
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: "12px", md: 0 }}
        mt="32px"
        px={{ base: "5%", md: 0 }}
      >
        <Flex alignItems="center" gap="12px">
          <Pagination
            color="primary"
            size="large"
            page={pageIndex + 1}
            onChange={(e, pageNumber) => gotoPage(pageNumber - 1)}
            count={pageCount}
            siblingCount={0}
            variant="outlined"
            shape="rounded"
          />
          {!isMobile && (
            <Text size="md">
              {totalCount === 0 ? 0 : pageIndex * pageSize + 1} -{" "}
              {pageIndex * pageSize + pageSize > totalCount
                ? totalCount
                : pageIndex * pageSize + pageSize}{" "}
              de {totalCount}
            </Text>
          )}
        </Flex>
        <Flex alignItems="center" gap="12px" mb={{ base: "32px", md: 0 }}>
          <Text size="md">Linhas por página</Text>
          <Select
            w={24}
            backgroundColor="white"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              gotoPage(0, Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
    </>
  );
};

export default TableWithPagination;
