import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputProps,
  Text,
} from "@chakra-ui/react";
import {
  cep,
  cnpj,
  cpf,
  currency,
  mobile,
  phone,
  date,
  formatCPF,
  formatMobile,
} from "../../services/utils";
import { useField } from "@unform/core";
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
} from "react";

interface Props extends InputProps {
  name: string;
  label?: string;
  labelColor?: string;
  labelWeight?: string;
  labelSize?: string;
}

interface InputMask extends InputHTMLAttributes<HTMLInputElement> {
  mask?:
    | "cep"
    | "currency"
    | "cpf"
    | "cnpj"
    | "phone"
    | "mobile"
    | "date"
    | "dateTime"
    | "time";
}

const InputField = ({
  name,
  label,
  mask,
  labelWeight,
  labelSize,
  labelColor,
  ...rest
}: Props & InputMask) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref: any) => {
        return ref.current.value;
      },

      setValue: (ref: any, value: string) => {
        ref.current.value = value;
      },

      clearValue: (ref: any) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  const handleKeyUp = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (mask === "cep") {
        cep(e);
      }
      if (mask === "currency") {
        currency(e);
      }
      if (mask === "cpf") {
        cpf(e);
      }
      if (mask === "cnpj") {
        cnpj(e);
      }
      if (mask === "phone") {
        phone(e);
      }
      if (mask === "mobile") {
        mobile(e);
      }
      if (mask === "date") {
        date(e);
      }
    },
    [mask]
  );

  function formatValue(value: string) {
    if (value === "" || !value) {
      return value;
    }

    if (mask === "cpf") {
      return formatCPF(value);
    }
    if (mask === "mobile") {
      return formatMobile(value);
    }

    return value;
  }

  return (
    <FormControl>
      {label && (
        <FormLabel
          color={labelColor}
          fontWeight={labelWeight}
          fontSize={labelSize}
          htmlFor={fieldName}
        >
          {label}
        </FormLabel>
      )}
      {mask && (
        <HStack>
          <Input
            onKeyUp={handleKeyUp}
            ref={inputRef}
            defaultValue={formatValue(defaultValue)}
            isInvalid={!!error}
            errorBorderColor="red.300"
            focusBorderColor="secondary.main"
            name={fieldName}
            {...rest}
          />
          {rest.required && (
            <Text alignSelf="flex-start" color="red.300">
              *
            </Text>
          )}
        </HStack>
      )}
      {!mask && (
        <HStack>
          <Input
            ref={inputRef}
            defaultValue={defaultValue}
            isInvalid={!!error}
            errorBorderColor="red.300"
            focusBorderColor="secondary.main"
            name={fieldName}
            {...rest}
          />
          {rest.required && (
            <Text
              fontSize={rest.fontSize}
              alignSelf="flex-start"
              color="red.300"
            >
              *
            </Text>
          )}
        </HStack>
      )}

      {error && <Text color="red.300">{error}</Text>}
    </FormControl>
  );
};

export default InputField;
