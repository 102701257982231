import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getCompanies } from "../../../services/companyService";
import { emulate, setRefAsync } from "../../../services/userService";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import SearchableSelect from "../../../components/SearchableSelect";
import ProcessInput from "../../purchase-order/save/components/ProcessInput";

const Emulation = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<any[]>([]);
  const [company, setCompany] = useState<any>([]);
  const [processId, setProcessId] = useState<string>();

  const auth = useAuthUser<any>();
  const getCompaniesOptions = async () => {
    const response = await getCompanies();
    if (response) {
      setCompanies(response);
      setLoading(false);
    }
  };
  useEffect(() => {
    getCompaniesOptions();
  }, []);

  const updateUserFunction = async () => {
    setLoading(true);
    const response = await emulate({
      id: auth?.userId,
      companyId: company,
    });
    if (response) {
      setLoading(false);
      toast({
        title: "Usuário editado.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const setRef = async () => {
    setLoading(true);
    const response = await setRefAsync(processId ?? "", company);

    if (response) {
      setLoading(false);
      toast({
        title: "O processo agora aparece para o cliente.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  };
  return (
    <Box width="100%">
      <Heading size="lg" fontWeight="normal" mb="24px">
        <Flex alignItems="center" gap="12px">
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "32px", color: "var(--icon-color)" }}
          >
            group
          </span>{" "}
          Emular clientes
        </Flex>
      </Heading>
      <Box backgroundColor="white" borderRadius="8px" p="24px">
        <Heading size="sm" fontWeight="normal" mb="8px">
          Empresa
        </Heading>
        <SearchableSelect
          options={companies.map((c) => ({ value: c.id, label: c.name }))}
          value={company}
          setValue={(value) => setCompany(value)}
        />
      </Box>
      <Flex justifyContent="flex-end" mb="32px">
        <Button
          marginTop="32px"
          onClick={() => updateUserFunction()}
          isLoading={loading}
          backgroundColor="var(--icon-color)"
          color="white"
        >
          Emular
        </Button>
      </Flex>
      <Divider />
      <Heading size="md" fontWeight="normal" mt="64px">
        Adicionar ref de cliente
      </Heading>
      <Text mt="8px">
        Serve para o processo permanecer vinculado a CVF, mas aparecer para o
        cliente
      </Text>
      <ProcessInput {...{ processId, setProcessId }} />
      <Flex justifyContent="flex-end" mb="32px">
        <Button
          marginTop="32px"
          onClick={() => setRef()}
          isLoading={loading}
          backgroundColor="var(--icon-color)"
          color="white"
        >
          Salvar Ref
        </Button>
      </Flex>
    </Box>
  );
};

export default Emulation;
