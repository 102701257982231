import {
  Box,
  Flex,
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Heading,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getProcesses } from "../../../services/processService";
import { Tag } from "../../../models/Filter";
import { useMediaQuery } from "react-responsive";
import LoadingTable from "../../../components/LoadingTable";
import LocalPagination from "../../../components/LocalPagination";
import ProcessCard from "../../processes/components/ProcessCard";

const ProcessesModal = ({
  isOpen,
  onClose,
  filter,
}: {
  isOpen: boolean;
  onClose: () => void;
  filter: { property: string; value: string };
}) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [tags, setTags] = useState<Tag[]>([]);
  const [page, setPage] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const get = async (localPageSize?: number, localPageNumber?: number) => {
    const localPs = localPageSize ?? pageSize;
    const localPn = localPageNumber ?? pageIndex;

    const response = await getProcesses({
      pageSize: localPs,
      pageNumber: localPn,
      ncm: tags
        .filter((t) => t.type === "ncm")
        ?.map((t) => t.value)
        .join("|"),
      departurePort: tags
        .filter((t) => t.type === "departurePort")
        ?.map((t) => t.value)
        .join("|"),
      customsAgent: tags
        .filter((t) => t.type === "customsAgent")
        ?.map((t) => t.value)
        .join("|"),
      cvfRef: tags
        .filter((t) => t.type === "cvfRef")
        ?.map((t) => t.value)
        .join("|"),
      dischargePort: tags
        .filter((t) => t.type === "dischargePort")
        ?.map((t) => t.value)
        .join("|"),
      companyId: tags
        .filter((t) => t.type === "companyId")
        ?.map((t) => t.value)
        .join("|"),
      countryOfOrigin: tags
        .filter((t) => t.type === "countryOfOrigin")
        ?.map((t) => t.value)
        .join("|"),
      exporter: tags
        .filter((t) => t.type === "exporter")
        ?.map((t) => t.value)
        .join("|"),
    });
    setPage(response.data);
    return response;
  };
  useEffect(() => {
    get();
  }, [tags]);

  useEffect(() => {
    if (filter) {
      setTags([
        { type: filter.property, label: filter.property, value: filter.value },
      ]);
    }
  }, [filter]);

  return (
    <Modal isOpen={isOpen} size={"6xl"} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader w="100%">
          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <Heading fontWeight="normal" size="lg">
              Processos
            </Heading>
            <CloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box p={{ base: "0px", md: "16px" }} pt="0">
            <Flex flexDir="column" my="32px">
              <Box h="550px" overflowY="auto">
                {loading ? (
                  <LoadingTable />
                ) : (
                  page.map((p) => <ProcessCard isDashboard {...{ p }} />)
                )}
              </Box>
              <LocalPagination
                {...{
                  setLoading,
                  tags,
                  setPage,
                  get,
                  pageIndex,
                  setPageIndex,
                  pageSize,
                  setPageSize,
                }}
              />
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ProcessesModal;
