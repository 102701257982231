import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import {
  addPermission,
  removePermission,
  getCompanies,
} from "../../../services/companyService";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {
  getPermissions,
  getPermissionsByCompanyId,
} from "../../../services/permissionService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FaKey, FaPlusCircle, FaUser } from "react-icons/fa";
import SearchableSelect from "../../../components/SearchableSelect";

const CompanyPermissions = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<any[]>([]);
  const [companyPermissions, setCompanyPermissions] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [permission, setPermission] = useState<any>();
  const [isNew, setIsNew] = useState(false);
  const [company, setCompany] = useState<any>({});
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const getCompaniesOptions = async () => {
    const response = await getCompanies();
    if (response) {
      setCompanies(response);
      setLoading(false);
    }
  };

  const getAllPermissions = async () => {
    const response = await getPermissions();
    if (response) {
      setPermissions(response);
      setLoading(false);
    }
  };

  const getCompanyPermissions = async () => {
    const response = await getPermissionsByCompanyId(company);
    if (response) {
      setCompanyPermissions(response);
      setLoading(false);
    }
  };

  const remove = async (permissionId: string) => {
    try {
      setLoading(true);
      const response = await removePermission({
        permissionId,
        companyId: company,
      });
      if (response) {
        toast({
          title: "Permissão excluida da empresa.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        await getCompanyPermissions();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCompaniesOptions();
  }, []);

  useEffect(() => {
    if (company) getCompanyPermissions();
  }, [company]);

  useEffect(() => {
    if (isNew) getAllPermissions();
  }, [isNew]);

  const addPermissionFunction = async () => {
    try {
      setLoading(true);
      const response = await addPermission({
        permissionId: permission,
        companyId: company,
      });

      if (response) {
        toast({
          title: "Permissão adicionada na empresa.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        getCompanyPermissions();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box overflowY="auto">
      <Box width="100%">
        <Heading size="lg" fontWeight="normal">
          <Flex alignItems="center" gap="12px">
            <Flex color="var(--icon-color)">
              <FaKey fontSize={isMobile ? 18 : 24} />
              <FaUser fontSize={isMobile ? 18 : 24} />
            </Flex>
            Gestão de permissões
          </Flex>
        </Heading>
        <Box backgroundColor="white" p="24px" borderRadius="8px" mt="24px">
          <Heading size="sm" fontWeight="sm" mb="8px">
            Empresa
          </Heading>
          <SearchableSelect
            options={companies.map((c) => ({ value: c.id, label: c.name }))}
            value={company}
            setValue={(value) => setCompany(value)}
          />
          <Flex flexDir="column" gap="16px" marginTop="32px" minH="300px">
            <Heading size="md" fontWeight="normal" mb="32px">
              Permissões da empresa
            </Heading>
            {companyPermissions.map((p) => (
              <Flex alignItems="center" gap="8px">
                <Heading size="sm" fontWeight="normal">
                  {p.name}
                </Heading>
                <Popover placement="top">
                  <PopoverTrigger>
                    <FontAwesomeIcon cursor="pointer" icon={faTrash} />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverHeader fontWeight="semibold">
                      Confirmação
                    </PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>Tem certeza que deseja excluir?</PopoverBody>
                    <PopoverFooter display="flex" justifyContent="flex-end">
                      <ButtonGroup size="sm">
                        <Button
                          onClick={() => {
                            remove(p.id);
                          }}
                          isLoading={loading}
                          colorScheme="red"
                          position="relative"
                          aria-label="Deletar"
                        >
                          Excluir
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              </Flex>
            ))}
          </Flex>
          {isNew ? (
            <Flex flexDir="column" marginBottom="32px" gap="8px">
              <Select
                placeholder="Selecione uma permissão"
                value={permission}
                onChange={(e) => setPermission(e.target.value)}
              >
                {permissions.map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))}
              </Select>
              <ButtonGroup justifyContent="flex-end" mt="24px">
                <Button
                  backgroundColor="white"
                  borderColor="var(--icon-color)"
                  borderWidth="1px"
                  color="var(--icon-color)"
                  onClick={() => setIsNew(false)}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={() => addPermissionFunction()}
                  isLoading={loading}
                  backgroundColor="var(--icon-color)"
                  color="white"
                >
                  Adicionar
                </Button>
              </ButtonGroup>
            </Flex>
          ) : (
            <Button
              backgroundColor="white"
              borderColor="var(--icon-color)"
              borderWidth="1px"
              onClick={() => setIsNew(true)}
              color="var(--icon-color)"
            >
              <Flex justifyContent="center" alignItems="center" gap="8px">
                <FaPlusCircle />
                Adicionar permissão
              </Flex>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyPermissions;
