import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  CloseButton,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FaFileExcel, FaPlus } from "react-icons/fa";
import { downloadFile } from "../../../../services/processService";
import { DateTime } from "luxon";
import { columns } from "./columns";

const ColumnsModal = ({
  isOpen,
  onClose,
  selectedColumns,
  setSelectedColumns,
  tags,
}: {
  isOpen: boolean;
  onClose: () => void;
  selectedColumns: string[];
  setSelectedColumns: Dispatch<SetStateAction<string[]>>;
  tags: any[];
}) => {
  useEffect(() => {
    const storageColumns = localStorage.getItem(`excel_columns`);
    if (storageColumns) {
      setSelectedColumns(JSON.parse(storageColumns));
    } else {
      setSelectedColumns(columns.map((c) => c.index));
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Heading fontSize="lg">Exportar para Excel</Heading>
            <CloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box
            paddingY="16px"
            minHeight="20vh"
            maxHeight="50vh"
            overflowY="scroll"
            paddingLeft="4px"
          >
            <Checkbox
              isChecked={
                selectedColumns.length > 0 &&
                columns.every((dd) =>
                  selectedColumns.some((d) => dd.index === d)
                )
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedColumns(columns.map((c) => c.index));
                } else {
                  setSelectedColumns([]);
                }
              }}
              mb="24px"
            >
              Selecionar todas
            </Checkbox>
            <CheckboxGroup
              value={selectedColumns}
              onChange={(e) => setSelectedColumns(e?.map((c) => c.toString()))}
            >
              <Flex flexDirection="column">
                {columns?.map((e) => (
                  <Checkbox key={e.index} value={e.index}>
                    {e.label}
                  </Checkbox>
                ))}
              </Flex>
            </CheckboxGroup>
          </Box>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              backgroundColor="var(--icon-color)"
              color="white"
              onClick={onClose}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ColumnsModal;
