import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Select,
  Spinner,
  TableContainer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Tag } from "../../models/Filter";
import { PieChart } from "@mui/x-charts";
import Header from "../../components/Header/Header";
import { getDashboard } from "../../services/processService";
import {
  DashboardViewModel,
  GraphViewModel,
  dashboardColors as colors,
} from "../../models/Dashboard";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useMediaQuery } from "react-responsive";
import Filter from "../../components/Filter";
import DataList from "./components/DataList";
import SupliersTable from "./components/SuppliersTable";
import DatePickerField from "../../components/DatePickerField";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import HomeHeader from "../../components/Header/HomeHeader";
import FilterModal from "../../components/FilterModal";
import ProcessesModal from "./components/ProcessesModal";
import SearchableSelect from "../../components/SearchableSelect";
import { CompanyFormData } from "../Configurations/tabs/types";
import { useCompany } from "../../contexts/CompanyContext";
import { getCompanies } from "../../services/companyService";

const Dashboard = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [dashboardData, setDashboardData] = useState<DashboardViewModel>();
  const [client, setClient] = useState<string>();
  const [clients, setClients] = useState<CompanyFormData[]>([]);
  const [graphs, setGraphs] = useState<GraphViewModel[]>([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [loadingGraphs, setLoadingGraphs] = useState(false);
  const [graphType, setGraphType] = useState<string>("1");
  const [selectedGraphs, setSelectedGraphs] = useState<(string | number)[]>([
    "Canais",
  ]);
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState<Tag[]>([]);
  const [initialDate, setInitialDate] = useState<Date>();
  const [initialDateValue, setInitialDateValue] = useState<string>();
  const [finalDate, setFinalDate] = useState<Date>();
  const [finalDateValue, setFinalDateValue] = useState<string>();
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "USD",
  });

  const { currentCompany } = useCompany();

  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    get();
  }, [tags, graphType, client, currentCompany]);

  useEffect(() => {
    if (initialDate && finalDate) get();
  }, [initialDate, finalDate]);

  useEffect(() => {
    if (dashboardData)
      setGraphs((a) => {
        let newGraphs = [...dashboardData.graphs];
        // newGraphs = newGraphs.filter((g) =>
        //   selectedGraphs.some((sg) => g.title === sg)
        // );
        return newGraphs;
      });
  }, [selectedGraphs]);

  const auth = useAuthUser<any>();

  useEffect(() => {
    const getClients = async () => {
      const response = await getCompanies(false);
      if (response) setClients(response);
    };
    getClients();
  }, []);

  const get = async () => {
    setLoading(true);
    const response: DashboardViewModel = await getDashboard({
      graphType,
      initialDate: initialDateValue,
      finalDate: finalDateValue,
      type: currentCompany,
      companyId: client,
      ncm: tags
        .filter((t) => t.type === "ncm")
        ?.map((t) => t.value)
        .join("|"),
      cvfRef: tags
        .filter((t) => t.type === "cvfRef")
        ?.map((t) => t.value)
        .join("|"),
      countryOfOrigin: tags
        .filter((t) => t.type === "origin")
        ?.map((t) => t.value)
        .join("|"),
      exporter: tags
        .filter((t) => t.type === "supplier")
        ?.map((t) => t.value)
        .join("|"),
      product: tags
        .filter((t) => t.type === "product")
        ?.map((t) => t.value)
        .join("|"),
    });

    if (response) {
      setDashboardData(response);
      setGraphs((a) => {
        let newGraphs = [...response.graphs];
        // newGraphs = newGraphs.filter((g) =>
        //   selectedGraphs.some((sg) => g.title === sg)
        // );
        return newGraphs;
      });
    }
    setLoading(false);
  };
  const targetRef = useRef(null);
  const options = {
    resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
    },
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
    },
  };

  const handleGeneratePDF = () => {
    setIsPrinting(true);
    setTimeout(() => {
      setIsPrinting(false);
    }, 600);
  };

  useEffect(() => {
    if (isPrinting)
      setTimeout(() => {
        generatePDF(targetRef, options);
      }, 100);
  }, [isPrinting]);

  const extraFilters = [
    <Box>
      <Heading size="sm" mb="8px">
        Valores dos gráficos
      </Heading>
      <Select
        w={{ base: undefined, md: "16vw" }}
        backgroundColor="white"
        value={graphType}
        onChange={(e) => setGraphType(e.target.value)}
      >
        <option value="1">Por invoice</option>
        <option value="2">Por nº de processo</option>
      </Select>
    </Box>,
    <Box>
      <Heading size="sm" mb="8px">
        Início do período
      </Heading>
      <DatePickerField
        width={isMobile ? "80vw" : "16vw"}
        date={initialDate}
        setDate={setInitialDate}
        dateValue={initialDateValue}
        setDateValue={setInitialDateValue}
      />
    </Box>,
    <Box>
      <Heading size="sm" mb="8px">
        Fim do período
      </Heading>
      <DatePickerField
        width={isMobile ? "80vw" : "16vw"}
        date={finalDate}
        setDate={setFinalDate}
        dateValue={finalDateValue}
        setDateValue={setFinalDateValue}
      />
    </Box>,
    <Button
      pos="relative"
      top="24px"
      backgroundColor="var(--icon-color)"
      color="white"
      onClick={handleGeneratePDF}
    >
      Gerar pdf
    </Button>,
  ];
  return (
    <Box height="100%">
      <Header />
      <Flex
        backgroundColor="var(--main-bg-color)"
        alignItems="center"
        flexDirection="column"
      >
        <Flex width="90%" mt="48px">
          <Heading size="lg" mb="24px" fontWeight="normal">
            Dashboard
          </Heading>
        </Flex>
        <Flex
          backgroundColor="var(--filter-color)"
          borderRadius="8px"
          width="90%"
          p="32px"
          mb="48px"
        >
          <FilterModal
            extraFilters={
              auth.position > 1
                ? [
                    <Box>
                      <Heading size="sm" mb="8px">
                        Clientes
                      </Heading>
                      <SearchableSelect
                        backgroundColor="white"
                        options={[
                          {
                            value: undefined,
                            label: "Emulada",
                          },
                          ...clients.map((c) => ({
                            value: c.id,
                            label: c.name,
                            subLabel: c.cnpj,
                          })),
                        ]}
                        name="clientId"
                        setValue={(value) => {
                          setClient(value);
                        }}
                      />
                    </Box>,
                    ...extraFilters,
                  ]
                : extraFilters
            }
            {...{
              isOpen,
              onClose,
              tags,
              setTags,
            }}
          />
          {isMobile ? (
            <Flex w="100%" alignItems="center" flexDir="column">
              <Button
                w="80%"
                backgroundColor="var(--icon-color)"
                color="white"
                onClick={onOpen}
              >
                Filtrar documentos
              </Button>
            </Flex>
          ) : (
            <Filter
              extraFilters={
                auth.position > 1
                  ? [
                      ...extraFilters,
                      <Box>
                        <Heading size="sm" mb="8px">
                          Clientes
                        </Heading>
                        <SearchableSelect
                          width={isMobile ? "80vw" : "16vw"}
                          backgroundColor="white"
                          options={[
                            {
                              value: undefined,
                              label: "Emulada",
                            },
                            ...clients.map((c) => ({
                              value: c.id,
                              label: c.name,
                              subLabel: c.cnpj,
                            })),
                          ]}
                          name="clientId"
                          setValue={(value) => {
                            setClient(value);
                          }}
                        />
                      </Box>,
                    ]
                  : extraFilters
              }
              {...{
                tags,
                setTags,
              }}
            />
          )}
        </Flex>
        <Box ref={targetRef} w={{ base: "95%", md: "90%" }}>
          {isPrinting && (
            <Flex w="100%" justifyContent="flex-end">
              <Image src="logo.png" height="80px" marginLeft="12px" />
            </Flex>
          )}
          {initialDate && finalDate && (
            <Heading mb="24px" fontWeight="normal" size="md">
              Período: {initialDateValue} - {finalDateValue}
            </Heading>
          )}
          <Flex flexDirection={isMobile ? "column" : "row"} gap="12px">
            <Flex
              p="24px"
              backgroundColor="white"
              width={{ base: "100%", md: "30%" }}
              minHeight="500px"
              flexDir="column"
              borderRadius="8px"
            >
              <Heading size="md" mb="48px" fontWeight="semibold">
                Canais
              </Heading>
              <Flex
                justifyContent="center"
                h="300px"
                w={{
                  base: "100%",
                  md: graphs[0]?.values.length === 0 ? "100%" : "125%",
                }}
              >
                {loading || loadingGraphs ? (
                  <Spinner size="xl" />
                ) : graphs[0]?.values.length === 0 ? (
                  <Flex justifyContent="center" alignItems="center">
                    Ainda não há dados de canais.
                  </Flex>
                ) : (
                  <PieChart
                    slotProps={{ legend: { hidden: true } }}
                    colors={colors} // Use palette
                    series={[
                      {
                        data:
                          graphs[0]?.values.map((v) => ({
                            label: v.label,
                            value:
                              graphType === "1" ? v.usd : v.numberOfProcesses,
                          })) ?? [],
                        innerRadius: 100,
                        outerRadius: 150,
                        cx: isMobile ? 175 : undefined,
                      },
                    ]}
                  />
                )}
              </Flex>
              {graphs[0]?.before ? (
                <Flex w="100%" fontWeight="normal" justifyContent="center">
                  <Text
                    fontWeight="semibold"
                    size="sm"
                    color={(graphs[0]?.before ?? 0) > 0 ? "green" : "red"}
                  >
                    <Flex gap="4px" mr="4px" alignItems="center">
                      {(graphs[0]?.before ?? 0) > 0 ? (
                        <FaArrowUp />
                      ) : (
                        <FaArrowDown />
                      )}
                      {Math.abs(graphs[0]?.before)}%
                    </Flex>
                  </Text>
                  <Text size="sm">
                    {(graphs[0]?.before ?? 0) > 0 ? "maior" : "menor"} que o
                    período anterior
                  </Text>
                </Flex>
              ) : (
                <Text></Text>
              )}
            </Flex>
            <Flex
              backgroundColor="white"
              width={{ base: "100%", md: "70%" }}
              minHeight="500px"
              flexDir="column"
              borderRadius="8px"
            >
              <Heading p="27px" size="md" fontWeight="semibold">
                Fonecedores
              </Heading>
              {loading || loadingGraphs ? (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  w="100%"
                  h="100%"
                >
                  <Spinner size="xl" />
                </Flex>
              ) : (
                <TableContainer>
                  <SupliersTable
                    graphType={graphType}
                    formatter={formatter}
                    page={
                      graphs
                        .find((g) => g.title === "Fornecedor")
                        ?.values.slice(0, 5) ?? []
                    }
                  />
                </TableContainer>
              )}
            </Flex>
          </Flex>
          <Flex
            flexDirection={isMobile ? "column" : "row"}
            gap="12px"
            mb="24px"
            mt="24px"
          >
            <Flex
              className="dashboard-card"
              width={{ base: "100%", md: "30%" }}
            >
              <Heading mb="12px" size="sm" fontWeight="semibold">
                Processos
              </Heading>
              <Heading size="lg">{dashboardData?.count}</Heading>
              {dashboardData?.countBefore ? (
                <Text
                  fontWeight="semibold"
                  mt="12px"
                  size="sm"
                  color={
                    (dashboardData?.countBefore ?? 0) > 0 ? "green" : "red"
                  }
                >
                  <Flex gap="4px" mr="4px" alignItems="center">
                    {(dashboardData?.countBefore ?? 0) > 0 ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )}
                    {Math.abs(dashboardData?.countBefore)}% P.A.
                  </Flex>
                </Text>
              ) : (
                <Text></Text>
              )}
            </Flex>

            <Flex
              className="dashboard-card"
              width={{ base: "100%", md: undefined }}
            >
              <Heading mb="12px" size="sm" fontWeight="semibold">
                Total invoice
              </Heading>
              <Heading size="lg">U$ {dashboardData?.totalInvoice}</Heading>
              {dashboardData?.totalInvoiceBefore ? (
                <Text
                  fontWeight="semibold"
                  mt="12px"
                  size="sm"
                  color={
                    (dashboardData?.totalInvoiceBefore ?? 0) > 0
                      ? "green"
                      : "red"
                  }
                >
                  <Flex gap="4px" mr="4px" alignItems="center">
                    {(dashboardData?.totalInvoiceBefore ?? 0) > 0 ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )}
                    {Math.abs(dashboardData?.totalInvoiceBefore)}% P.A.
                  </Flex>
                </Text>
              ) : (
                <Text></Text>
              )}
            </Flex>
            {dashboardData?.transitTime && (
              <Flex
                className="dashboard-card"
                width={{ base: "100%", md: "50%" }}
              >
                <Heading mb="12px" size="sm" fontWeight="semibold">
                  Transit time:
                </Heading>
                <Heading size="lg">{dashboardData?.transitTime} dias</Heading>
                {dashboardData?.transitTimeBefore ? (
                  <Text
                    fontWeight="semibold"
                    mt="12px"
                    size="sm"
                    color={
                      (dashboardData?.transitTimeBefore ?? 0) > 0
                        ? "green"
                        : "red"
                    }
                  >
                    <Flex gap="4px" mr="4px" alignItems="center">
                      {(dashboardData?.transitTimeBefore ?? 0) > 0 ? (
                        <FaArrowUp />
                      ) : (
                        <FaArrowDown />
                      )}
                      {Math.abs(dashboardData?.transitTimeBefore)}% P.A.
                    </Flex>
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </Flex>
            )}
            {dashboardData?.nacionalization && (
              <Flex
                className="dashboard-card"
                width={{ base: "100%", md: "50%" }}
              >
                <Heading mb="12px" size="sm" fontWeight="semibold">
                  Nacionalização:
                </Heading>
                <Heading size="lg">
                  {dashboardData.nacionalization} dias
                </Heading>
                {dashboardData?.nacionalizationBefore ? (
                  <Text
                    fontWeight="semibold"
                    mt="12px"
                    size="sm"
                    color={
                      (dashboardData?.nacionalizationBefore ?? 0) > 0
                        ? "green"
                        : "red"
                    }
                  >
                    <Flex gap="4px" mr="4px" alignItems="center">
                      {(dashboardData?.nacionalizationBefore ?? 0) > 0 ? (
                        <FaArrowUp />
                      ) : (
                        <FaArrowDown />
                      )}
                      {Math.abs(dashboardData?.nacionalizationBefore)}% P.A.
                    </Flex>
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </Flex>
            )}
            {dashboardData?.leadTime && (
              <Flex
                className="dashboard-card"
                width={{ base: "100%", md: "50%" }}
              >
                <Heading mb="12px" size="sm" fontWeight="semibold">
                  Lead time:
                </Heading>
                <Heading size="lg">{dashboardData?.leadTime} dias</Heading>
                {dashboardData?.leadTimeBefore ? (
                  <Text
                    fontWeight="semibold"
                    mt="12px"
                    size="sm"
                    color={
                      (dashboardData?.leadTimeBefore ?? 0) > 0 ? "green" : "red"
                    }
                  >
                    <Flex gap="4px" mr="4px" alignItems="center">
                      {(dashboardData?.leadTimeBefore ?? 0) > 0 ? (
                        <FaArrowUp />
                      ) : (
                        <FaArrowDown />
                      )}
                      {Math.abs(dashboardData?.leadTimeBefore)}% P.A.
                    </Flex>
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </Flex>
            )}
          </Flex>
          <Flex
            className="dashboard-card"
            borderRadius="8px"
            mb="24px"
            width={{ base: "100%", md: undefined }}
          >
            <HomeHeader />
          </Flex>
          <Flex
            flexDirection={isMobile ? "column" : "row"}
            gap="12px"
            mb="48px"
          >
            <DataList
              isPrinting={isPrinting}
              loading={loading || loadingGraphs}
              title="Países de origem"
              button="TODAS AS ORIGENS"
              graph={graphs.find((g) => g.title === "Origem")}
              {...{ formatter, graphType }}
            />
            <DataList
              isPrinting={isPrinting}
              loading={loading || loadingGraphs}
              title="Fornecedores"
              button="TODOS OS FORNECEDORES"
              graph={graphs.find((g) => g.title === "Fornecedor")}
              {...{ formatter, graphType }}
            />
            <DataList
              isPrinting={isPrinting}
              loading={loading || loadingGraphs}
              title="Armadores"
              button="TODOS OS ARMADORES"
              graph={graphs.find((g) => g.title === "Armador")}
              {...{ formatter, graphType }}
            />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
export default Dashboard;
