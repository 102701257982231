import "./App.css";
import Login from "./pages/login";
import { useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Forgot from "./pages/forgot";
import SignUp from "./pages/signUp";
import ChangePassword from "./pages/changePassword";
import Dashboard from "./pages/dashboard";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import Configurations from "./pages/Configurations";
import Tracking from "./pages/tracking";
import Processes from "./pages/processes";
import Documents from "./pages/documents";
import Process from "./pages/process";
import Account from "./pages/account";
import Financial from "./pages/financial";
import PurchaseOrders from "./pages/purchase-order";
import SavePurchaseOrder from "./pages/purchase-order/save";
import SaveProcess from "./pages/processes/save";

function App() {
  const isAuth = useIsAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();
  const unauthRoutes = ["login", "changepassword", "signup"];
  const ref = useRef();
  useEffect(() => {
    if (
      !isAuth &&
      unauthRoutes.every((u) => !location.pathname.toLowerCase().includes(u))
    ) {
      navigate("/login");
    }
  }, []);
  return (
    <Box>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/forgot" element={<Forgot />}></Route>
        <Route path="/signUp" element={<SignUp />}></Route>
        <Route path="/changePassword" element={<ChangePassword />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/configurations" element={<Configurations />}></Route>
        <Route path="/tracking" element={<Tracking />}></Route>
        <Route path="/account" element={<Account />}></Route>
        <Route path="/processes" element={<Processes />}></Route>
        <Route path="/saveProcess" element={<SaveProcess />}></Route>
        <Route path="/process" element={<Process />}></Route>
        <Route path="/documents" element={<Documents />}></Route>
        <Route path="/financial" element={<Financial />}></Route>
        <Route path="/purchaseOrders" element={<PurchaseOrders />}></Route>
        <Route
          path="/savePurchaseOrders"
          element={<SavePurchaseOrder />}
        ></Route>
      </Routes>
    </Box>
  );
}

export default App;
