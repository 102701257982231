import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Text,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { ProcessItemViewModel } from "../../../../models/Process";
import { Dispatch, SetStateAction } from "react";
import { currencyString } from "../../../../services/utils";

const ProcessItem = ({
  i,
  error,
  setError,
  index,
  items,
  setItems,
}: {
  i: ProcessItemViewModel;
  error: string | undefined;
  setError: Dispatch<SetStateAction<string | undefined>>;
  index: number;
  items: ProcessItemViewModel[];
  setItems: Dispatch<SetStateAction<ProcessItemViewModel[]>>;
}) => {
  return (
    <Flex
      gap="16px"
      alignItems="flex-end"
      flexDir={{ base: "column", md: "row" }}
    >
      <FormControl position="relative">
        <FormLabel>NCM: </FormLabel>
        <Input
          value={items[index].ncm}
          onChange={(e) =>
            setItems((items) => {
              const newItems = [...items];
              newItems[index].ncm = e.target.value;
              return newItems;
            })
          }
          name="ncm"
          placeholder="NCM"
        />
        <Text color="red.300" h="24px"></Text>
      </FormControl>
      <FormControl position="relative">
        <FormLabel>Quantidade: </FormLabel>
        <Input
          value={items[index].quantity?.replace("e", "")}
          onChange={(e) => {
            setError(undefined);
            setItems((items) => {
              const newItems = [...items];
              newItems[index].quantity = e.target.value.replace(/[^0-9]+/g, "");
              return newItems;
            });
          }}
          borderColor={
            error && (items[index].quantity?.length ?? 0) === 0
              ? "red"
              : undefined
          }
          name="quantity"
          placeholder="Quantidade"
        />
        <Text color="red.300" h="24px">
          {error &&
            (items[index].quantity?.length ?? 0) === 0 &&
            "Campo obrigatório"}
        </Text>
      </FormControl>
      <FormControl position="relative">
        <FormLabel>Produto: </FormLabel>
        <Input
          value={items[index].product}
          onChange={(e) => {
            setError(undefined);
            setItems((items) => {
              const newItems = [...items];
              newItems[index].product = e.target.value;
              return newItems;
            });
          }}
          borderColor={
            error && (items[index].product?.length ?? 0) === 0
              ? "red"
              : undefined
          }
          name="product"
          placeholder="Produto"
        />
        <Text color="red.300" h="24px">
          {error &&
            (items[index].product?.length ?? 0) === 0 &&
            "Campo obrigatório"}
        </Text>
      </FormControl>
      <FormControl position="relative">
        <FormLabel>Valor unitário: </FormLabel>
        <Input
          value={items[index].unitValue}
          onChange={(e) => {
            setError(undefined);
            setItems((items) => {
              const newItems = [...items];
              newItems[index].unitValue = currencyString(e.target.value);
              return newItems;
            });
          }}
          borderColor={
            error && (items[index].unitValue?.length ?? 0) === 0
              ? "red"
              : undefined
          }
          name="unitValue"
          placeholder="Valor unitário"
        />

        <Text color="red.300" h="24px">
          {error &&
            (items[index].unitValue?.length ?? 0) === 0 &&
            "Campo obrigatório"}
        </Text>
      </FormControl>
      <IconButton
        onClick={() =>
          setItems((items) => {
            const newItems = [...items];
            newItems.splice(index, 1);
            return newItems;
          })
        }
        backgroundColor="var(--icon-color)"
        color="white"
        aria-label="excluir"
        position="relative"
        bottom="24px"
        icon={<FaTrash />}
      />
    </Flex>
  );
};

export default ProcessItem;
