import {
  ButtonGroup,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Table,
  Text,
  useDisclosure,
  ModalHeader,
  CloseButton,
} from "@chakra-ui/react";
import Flag from "react-world-flags";
import { GraphValueViewModel } from "../../../models/Dashboard";
import DataListItem from "./DataListItem";

const ShowMoreModal = ({
  isOpen,
  isPrinting,
  property,
  onClose,
  formatter,
  graphType,
  values,
  title,
}: {
  isOpen: boolean;
  isPrinting: boolean;
  property: string;
  onClose: () => void;
  formatter: Intl.NumberFormat;
  graphType: string;
  values: GraphValueViewModel[];
  title: string;
}) => {
  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius="12px">
        <ModalHeader>
          <Flex justifyContent="space-between">
            <Heading size="lg" fontWeight="semibold">
              {title[0]}
              {title.substring(1).toLowerCase()}
            </Heading>
            <CloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody p="24px">
          <Flex flexDir="column" gap="24px">
            {values.map((v) => (
              <DataListItem
                property={property}
                {...{ isPrinting, v, formatter, graphType }}
              />
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShowMoreModal;
