import {
  Box,
  BoxProps,
  Button,
  Flex,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaTrash } from "react-icons/fa";

interface Props extends BoxProps {
  accept?: string;
  onFileChange: (value: File) => void;
  file: File | undefined;
  setFile: any;
  inputRef: any;
}

export default function DocumentUpload({
  accept,
  onFileChange,
  file,
  setFile,
  inputRef,
  ...rest
}: Props) {
  const toast = useToast();
  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const fsize = e?.currentTarget?.files
      ? e?.currentTarget?.files[0]?.size
      : 0;
    const fileSizeInMb = Math.round(fsize / 1024);
    if (
      e?.currentTarget?.files &&
      accept?.includes(e?.currentTarget?.files[0]?.type) &&
      fileSizeInMb < 51200
    ) {
      onFileChange(Object(e.currentTarget.files)[0]);
      setFile(Object(e.currentTarget.files)[0]);
    } else {
      toast({
        title: "Arquivo inválido",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
      e.currentTarget.files = null;
      onFileChange(Object({})[0]);
      setFile(Object({})[0]);
    }
  }
  const handleDeleteFile = () => {
    if (inputRef?.current?.value) {
      inputRef.current.value = "";
    }
    onFileChange(Object({})[0]);
    setFile(Object({})[0]);
  };
  return (
    <Box
      marginTop={0}
      border="2px solid #858585"
      borderRadius="15px"
      position="relative"
      overflow="hidden"
      {...rest}
    >
      <Flex
        direction="column"
        as="label"
        flex={1}
        height="100%"
        justifyContent="center"
        alignItems="center"
        w="100%"
      >
        <Text mb="20px" fontWeight="500" fontSize="20px" textAlign="center">
          Arraste e solte seu arquivo aqui ou
        </Text>

        <Button
          minW="188px"
          h="54px"
          py="24px"
          px="20px"
          fontSize="20px"
          fontWeight="600"
          backgroundColor="var(--icon-color)"
          color="white"
          display="flex"
          alignItems="center"
          borderRadius="15px"
        >
          <RiFileExcel2Fill size="25" style={{ marginRight: "16px" }} />
          {`Importar ${file ? "outros" : ""} dados do Excel`}
        </Button>
        {file ? (
          <Box cursor="pointer">
            <Text mt="20px" fontWeight="600" fontSize="18px" textAlign="center">
              Arquivo selecionado:
            </Text>
            <Flex alignItems="center" gap="8px">
              <Text fontWeight="600" fontSize="18px" textAlign="center">
                {file.name}
              </Text>
              <FaTrash style={{ zIndex: 2 }} onClick={handleDeleteFile} />
            </Flex>
          </Box>
        ) : (
          <>
            <Text
              mt="20px"
              color="gray.400"
              fontWeight="600"
              fontSize="18px"
              textAlign="center"
            >
              Tipos: xls, xlsx
            </Text>
            <Text
              color="gray.400"
              fontWeight="600"
              fontSize="18px"
              textAlign="center"
            >
              Tamanho máx: 50 Mb
            </Text>
          </>
        )}
        <Input
          ref={inputRef}
          pl="120%"
          opacity="0"
          type="file"
          accept={accept}
          position="absolute"
          onChange={handleFileChange}
          w="100%"
          h="100%"
          cursor="pointer"
        />
      </Flex>
    </Box>
  );
}
