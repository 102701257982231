import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Link,
  Progress,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import Header from "../../components/Header/Header";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { saveTracking, updateTracking } from "../../services/trackingService";
import { DateTime } from "luxon";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { getShipsGoByProcessId } from "../../services/shipsGoService";
import { FaAngleRight } from "react-icons/fa";
import { EventAvailableOutlined } from "@mui/icons-material";
import TimelineItem from "./components/TimelineItem";
import EditingTrack from "./components/editingTrack";

const Tracking = () => {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const navigate = useNavigate();
  const auth = useAuthUser<any>();
  const [processRef, setProcessRef] = useState<string>("");
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [oldStyle, setOldStyle] = useState(
    !auth.permissions.some((p: string) => p === "Tracking")
  );
  const [data, setData] = useState<any>({});
  const get = async () => {
    setLoading(true);
    if (id) {
      const response = await getShipsGoByProcessId(id);
      const isOldStyle = !!response.data;
      setOldStyle(isOldStyle);
      setProcessRef(response.cvfRef);
      setData(isOldStyle ? response.data : response);
      setLoading(false);
    }
  };

  useEffect(() => {
    get();
  }, [id]);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
        }
        return Math.min(oldProgress + 1, 100);
      });
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box h="100vh" overflow="hidden">
      <Header />
      <Flex
        flexDirection="column"
        backgroundColor="var(--main-bg-color)"
        paddingBottom="64px"
        h="100%"
        alignItems="center"
        overflowY="auto"
      >
        <Flex
          alignItems="center"
          gap="8px"
          mb="48px"
          width="90%"
          mt={{ base: "16px", md: "27px" }}
        >
          <Link
            color="var(--icon-color)"
            onClick={() => navigate("/processes")}
            size="md"
          >
            Processos
          </Link>
          <FaAngleRight />
          <Link
            color="var(--icon-color)"
            onClick={() => navigate(`/process?id=${id}`)}
            size="md"
          >
            Detalhes
          </Link>
          <FaAngleRight />
          <Text size="md">Tracking</Text>
        </Flex>

        <Flex width={{ base: "100%", md: oldStyle ? "45%" : "90%" }}>
          <Heading size="lg" fontWeight="normal" mb="32px" pl="32px">
            Rastreamento do Processo {data?.cvfRef}
          </Heading>
        </Flex>
        <Flex width="90%" justifyContent="center">
          <Flex
            borderRadius="8px"
            backgroundColor="white"
            width={{ base: "100%", md: oldStyle ? "45%" : "100%" }}
            padding="32px"
            paddingLeft={{ base: "32px", md: "64px" }}
            mb="32px"
            flexDirection="column"
          >
            {loading ? (
              <Flex
                w="100%"
                justifyContent="center"
                flexDir="column"
                alignItems="center"
                py="120px"
              >
                <Heading>Obtendo informações</Heading>
                <Progress
                  w="500px"
                  value={progress}
                  size="sm"
                  colorScheme="blue"
                />
              </Flex>
            ) : oldStyle ? (
              <>
                <Box>
                  <Box flexDir="column" alignItems="center">
                    {data?.map((d: any, index: number) => (
                      <TimelineItem
                        id={d.id}
                        processId={id as string}
                        get={get}
                        isStatus
                        isActual
                        isFirst={index === 0}
                        place={d.status}
                        departureDate={d.dateTime}
                      />
                    ))}
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Flex
                  w="100%"
                  gap="64px"
                  flexDir={{ base: "column", md: "row" }}
                >
                  <Box>
                    <Box>
                      <Flex flexDir="column" mb="64px">
                        <Flex alignItems="flex-start" gap="12px">
                          <EventAvailableOutlined
                            color="primary"
                            fontSize="medium"
                          />
                          <Heading size="sm" fontWeight="semibold">
                            Data de chegada
                            {data?.arrivalDate.isActual ? "" : " estimada"}:
                          </Heading>
                        </Flex>
                        <Heading size="lg" ml="38px">
                          {DateTime.fromISO(data?.arrivalDate.date).toFormat(
                            "dd/LL/yyyy"
                          )}
                        </Heading>
                      </Flex>
                    </Box>

                    <Box mb="32px">
                      <VStack spacing={0} align="stretch">
                        {data?.gateInDate && (
                          <TimelineItem
                            place={data?.pol}
                            isActual={
                              DateTime.fromISO(data?.gateInDate).startOf(
                                "day"
                              ) <= DateTime.now().startOf("day")
                            }
                            arrivalDate={data?.gateInDate}
                            isFirst
                          />
                        )}
                        <TimelineItem
                          place={`${data?.pol}, ${data?.fromCountry}`}
                          isActual={data?.departureDate.isActual}
                          departureDate={data?.departureDate.date}
                          isFirst={!data?.gateInDate}
                        />
                        {data?.tsPorts.map((ts: any, index: number) => (
                          <>
                            <TimelineItem
                              isFirst
                              isTransshipment
                              place={ts.port}
                              isActual={ts.departureDate.isActual}
                              arrivalDate={ts.departureDate.date}
                            />
                            <TimelineItem
                              isTransshipment
                              place={ts.port}
                              isActual={ts.arrivalDate.isActual}
                              departureDate={ts.arrivalDate.date}
                            />
                          </>
                        ))}
                        <TimelineItem
                          place={`${data?.pod}, ${data?.toCountry}`}
                          isActual={data?.arrivalDate.isActual}
                          arrivalDate={data?.arrivalDate.date}
                        />
                        {data?.gateOutDate && (
                          <TimelineItem
                            place={data?.pod}
                            isActual={
                              DateTime.fromISO(data?.gateOutDate).startOf(
                                "day"
                              ) <= DateTime.now().startOf("day")
                            }
                            departureDate={data?.gateOutDate}
                          />
                        )}
                      </VStack>
                    </Box>
                  </Box>
                  <Box w={{ base: "100%", md: "80%" }}>
                    <Box width="100%">
                      <iframe
                        width="100%"
                        title="CVFLocationMap"
                        height="640"
                        style={{ border: 0, display: "block" }}
                        src={`https://maps.google.com/maps?q=${encodeURI(
                          `${
                            data?.vesselLatitude === "Not Supported"
                              ? data?.tsPorts.find(
                                  (t: any) =>
                                    t.vesselLatitude !== "Not Supported"
                                )?.vesselLatitude
                              : data?.vesselLatitude
                          }, ${
                            data?.vesselLongitude === "Not Supported"
                              ? data?.tsPorts.find(
                                  (t: any) =>
                                    t.vesselLongitude !== "Not Supported"
                                )?.vesselLongitude
                              : data?.vesselLongitude
                          }`
                        )}&t=&z=8&ie=UTF8&iwloc=&output=embed`}
                      />
                    </Box>
                  </Box>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
export default Tracking;
