import {
  Box,
  CloseButton,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import Card from "../../Home/Components/Card";

const RedirectModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="center" mb="0px" mt="8px">
            <Heading fontWeight="normal">Para onde deseja ir?</Heading>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex w="100%" gap="32px" alignItems="center" mb="16px">
            <Card
              option={{
                title: "Processos",
                link: "/processes",
                icon: "inventory_2",
                position: "right",
              }}
            />
            <Heading fontWeight="normal" size="md">
              ou
            </Heading>
            <Card
              option={{
                title: "Financeiro",
                link: "/financial",
                icon: "account_balance",
                position: "right",
              }}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RedirectModal;
