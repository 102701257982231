import { useState, useEffect } from "react";
import {
  Heading,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faEnvelope,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { AxiosError } from "axios";
import { login } from "../../services/userService";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { DateTime, Duration } from "luxon";
import CompanySelectModal from "./components/CompanySelectModal";

function Login() {
  const { register, handleSubmit, formState } = useForm();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const redirect = new URLSearchParams(window.location.search).get("redirect");
  const processId = new URLSearchParams(window.location.search).get(
    "processId"
  );
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  const navigate = useNavigate();

  const isAuth = useIsAuthenticated();
  const signIn = useSignIn();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit = async (data: any) => {
    setLoading(true);
    setError("");

    try {
      const response = await login({
        email: data.email,
        password: data.password,
      });
      if (response?.data.token) {
        const expiration = 86400000;
        signIn({
          auth: {
            token: response.data.token,
            type: "Bearer",
          },
          userState: {
            userId: response.data.user.id,
            name: response.data.user.name,
            firstname: response.data.user.firstname,
            lasstname: response.data.user.lasstname,
            company: response.data.user.company,
            admin: response.data.user.admin,
            permissions: response.data.user.permissions,
            position: response.data.user.position,
            expiresIn: DateTime.now().plus(Duration.fromMillis(expiration)),
          },
        });

        if (response.data.user.position > 1) {
          onOpen();
          return;
        }

        navigate(
          redirect
            ? "/purchaseOrders"
            : processId
              ? `/tracking?id=${processId}`
              : "/"
        );
      } else {
        setError("E-mail ou senha inválidos");
      }
    } catch (err) {
      if (err && err instanceof AxiosError)
        setError("Email ou senha inválidos");
      else if (err && err instanceof Error)
        setError("Email ou senha inválidos");

      console.log("Error: ", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (redirect && isAuth) {
      navigate("/purchaseOrders");
    } else if (processId && isAuth) {
      navigate(`/tracking?id=${processId}`);
    } else if (!processId && isAuth) {
      navigate("/");
    }
  }, [processId]);

  return (
    <Box
      height="100vh"
      overflow="hidden"
      backgroundColor="var(--main-bg-color)"
    >
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Flex
            position="relative"
            width={isMobile ? "45vh" : "60vh"}
            backgroundColor="white"
            padding="64px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderRadius="12px"
          >
            <Image width="20vh" src="logo.png" />
            {/* <Heading color='red'>TRIAL</Heading> */}
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <FormControl marginY="64px">
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<FontAwesomeIcon icon={faEnvelope} />}
                    color="gray.300"
                  />
                  <Input
                    {...register("email", {
                      required: "E-mail e senha são obrigatórios.",
                    })}
                    type="email"
                    placeholder="E-mail"
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    children={<FontAwesomeIcon icon={faLock} />}
                    pointerEvents="none"
                    color="gray.300"
                  />
                  <Input
                    {...register("password", {
                      required: "E-mail e senha são obrigatórios.",
                    })}
                    type={showPassword ? "text" : "password"}
                    placeholder="Senha"
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      backgroundColor="transparent"
                      size="sm"
                      onClick={handleShowClick}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormHelperText textAlign="right">
                  <Link to="/forgot">esqueceu a senha?</Link>
                </FormHelperText>
                {(formState.errors.password ||
                  formState.errors.email ||
                  error) && (
                    <Text color="red" marginTop="16px">
                      {error ??
                        (typeof formState.errors.password?.message === "string" &&
                          formState.errors.password?.message) ??
                        (typeof formState.errors.email?.message === "string" &&
                          formState.errors.email?.message)}
                    </Text>
                  )}
              </FormControl>
              <Button
                marginY="32px"
                borderRadius="3px"
                type="submit"
                variant="solid"
                backgroundColor="var(--icon-color)"
                color="white"
                width="full"
                isLoading={loading}
              >
                Login
              </Button>
            </form>
          </Flex>
        </Box>
        <CompanySelectModal {...{ isOpen, onClose }} />
      </Flex>
    </Box>
  );
}
export default Login;
