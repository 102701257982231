import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SelectProps,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useMemo, useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
export interface SelectOption {
  value: any;
  label: any;
  subLabel?: any;
}

export interface CustomSelectProps extends SelectProps {
  name?: string;
  placeholder?: string;
  defaultSelectValue?: SelectOption;
  options: SelectOption[];
  setValue: (value: string) => void;
  isLoading?: boolean;
  isDynamicField?: boolean;
  width?: any;
}

const SearchableSelect = ({
  placeholder,
  defaultSelectValue,
  setValue,
  isLoading,
  options,
  width,
}: CustomSelectProps) => {
  const [selected, setSelected] = useState<SelectOption | undefined>(
    defaultSelectValue
  );
  const [searchTerm, setSearchTerm] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm]);

  const handleSelect = (option: SelectOption) => {
    setValue(option.value);
    setSelected(option);
    setSearchTerm("");
    onClose();
  };
  return (
    <>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} matchWidth>
        <PopoverTrigger>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              w={width}
              backgroundColor="white"
              defaultValue={defaultSelectValue?.label}
              ref={inputRef}
              placeholder={placeholder}
              value={selected ? selected.label : searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setSelected(undefined);
                onOpen();
              }}
              onClick={() => {
                onOpen();
                setTimeout(() => {
                  inputRef.current?.select();
                }, 200);
              }}
            />
            <InputRightElement>
              {isLoading ? (
                <Spinner />
              ) : isOpen ? (
                <FaChevronUp />
              ) : (
                <FaChevronDown />
              )}
            </InputRightElement>
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent
          width="100%"
          maxHeight="300px"
          overflowY="auto"
          boxShadow="lg"
        >
          <VStack align="stretch" spacing={0}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <Box
                  key={option.value}
                  p={2}
                  _hover={{
                    bg: "gray.100",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSelect(option)}
                >
                  <Flex justifyContent="space-between" px="8px">
                    <Text>{option.label}</Text>
                    <Text textStyle="italic" fontWeight="300">
                      {option.subLabel}
                    </Text>
                  </Flex>
                </Box>
              ))
            ) : (
              <Text p={2} color="gray.500" textAlign="center">
                No items found
              </Text>
            )}
          </VStack>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default SearchableSelect;
