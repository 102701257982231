import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { getProcessIdByCvfRef } from "../../../../services/processService";
import { FormHandles } from "@unform/core";
import { FaCheck } from "react-icons/fa";
import InputField from "../../../../components/InputField";

const ProcessInput = ({
  formRef,
  processId,
  setProcessId,
}: {
  formRef?: RefObject<FormHandles>;
  processId: string | undefined;
  setProcessId: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const [cvfRef, setCvfRef] = useState("");
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const getProcessId = async () => {
    try {
      setError(undefined);
      setLoading(true);
      const response = await getProcessIdByCvfRef(cvfRef);

      if ((response?.length ?? 0) > 0) {
        setProcessId(response);
        if (formRef) {
          formRef.current?.setFieldValue("processId", response);
        }
      } else {
        setProcessId(undefined);
        if (formRef) {
          formRef.current?.setFieldValue("processId", undefined);
        }
        setError("Processo não encontrado");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cvfRef.length >= 3) {
      getProcessId();
    }
  }, [cvfRef]);

  return (
    <FormControl>
      <Box w="60%" mt={10}>
        <FormLabel>Nº do Processo: </FormLabel>
        <Flex alignItems="center" gap="24px">
          {formRef ? (
            <InputField
              name="processId"
              value={cvfRef}
              onChange={(e) => {
                setError(undefined);
                setProcessId(undefined);
                setCvfRef(e.target.value);
                formRef.current?.setErrors({
                  ...formRef.current.getErrors(),
                  processId: "",
                });
              }}
            />
          ) : (
            <Input
              value={cvfRef}
              onChange={(e) => {
                setError(undefined);
                setProcessId(undefined);
                setCvfRef(e.target.value);
              }}
            />
          )}
          {loading && <Spinner size="sm" color="var(--icon-color)" />}
        </Flex>
        {processId ? (
          <Flex
            color="green.300"
            alignItems="center"
            gap="4px"
            fontSize="16px"
            mt="4px"
          >
            <FaCheck />
            <Text>Processo selecionado com sucesso</Text>
          </Flex>
        ) : error ? (
          <Text color="red.300">{error}</Text>
        ) : (
          ""
        )}
      </Box>
    </FormControl>
  );
};

export default ProcessInput;
