import {
  Badge,
  Box,
  Flex,
  Heading,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { PurchaseOrderViewModel } from "../../../models/PurchaseOrder";
import { signPurchaseOrder } from "../../../services/purchaseOrderService";
import { DateTime } from "luxon";
import PurchaseOrderModal from "./PurchaseOrderModal";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const PurchaseOrderCard = ({
  p,
  onOpen,
  get,
}: {
  p: PurchaseOrderViewModel;
  onOpen: () => void;
  get: () => Promise<void>;
}) => {
  const auth = useAuthUser<any>();
  const [loading, setLoading] = useState(false);
  const formatterUsd = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "USD",
  });

  const handleSign = async (position: string) => {
    try {
      setLoading(true);
      const response = await signPurchaseOrder(p.id, position);
      if (response) {
        await get();
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  return (
    <Flex
      flexDir={{ base: "column", md: "row" }}
      p="16px"
      gap="24px"
      borderRadius="8px"
      backgroundColor="white"
      justifyContent="space-between"
    >
      <Flex>
        <Flex flexDir="column" h="100%" justifyContent="center">
          <Heading ml="32px" mr="64px" size="md" fontWeight="semibold">
            ALFA {p.process.cvfRef} / PO {p.poNumber}
          </Heading>
        </Flex>
      </Flex>
      <Flex flexDir="column" mt="10px">
        <Heading size="sm" fontWeight="semibold" mb="4px">
          Incoterm
        </Heading>

        <Heading size="md" fontWeight="normal">
          {p.process.incoterm}
        </Heading>
      </Flex>
      <Flex flexDir="column" mt="10px">
        <Heading size="sm" fontWeight="semibold" mb="4px">
          Termo de pagamento
        </Heading>

        <Heading size="md" fontWeight="normal">
          {p.condition}
        </Heading>
      </Flex>

      <Flex flexDir="column" mt="10px">
        <Heading size="sm" fontWeight="semibold" mb="4px">
          Total pago
        </Heading>

        <Heading size="md" color="red.600" fontWeight="normal">
          {formatterUsd.format(p.totalPayed ?? 0)}
        </Heading>
      </Flex>
      <Flex flexDir="column" mt="10px">
        <Heading size="sm" fontWeight="semibold" mb="4px">
          Total a pagar
        </Heading>

        <Heading size="md" color="green.600" fontWeight="normal">
          {formatterUsd.format(p.totalToPay ?? 0)}
        </Heading>
      </Flex>
      <Flex flexDir="column" mt="10px">
        <Heading size="sm" fontWeight="semibold" mb="4px">
          Data de Chegada (Estimada)
        </Heading>

        <Heading size="md" fontWeight="normal">
          {DateTime.fromISO(p.process.arrivalDate ?? "").toFormat("dd/LL/yyyy")}
        </Heading>
      </Flex>
      <Flex flexDir="column" mt="10px">
        <Heading size="sm" fontWeight="semibold" mb="4px">
          Status
        </Heading>
        <Popover placement="top" trigger="hover">
          <PopoverTrigger>
            <Heading cursor="pointer" size="md" fontWeight="normal">
              <Flex>
                <Badge colorScheme={p.status ? "green" : "yellow"} p="8px">
                  {p.status ? "Assinado" : "Pendente"}
                </Badge>
              </Flex>
            </Heading>
          </PopoverTrigger>
          <PopoverContent w="150px">
            <Flex p="16px" flexDir="column" gap="12px">
              <Box>
                <Text fontSize="12px">CVF Distribuidora:</Text>
                <Badge
                  onClick={() =>
                    auth.position === 2 && handleSign("Administration")
                  }
                  cursor="pointer"
                  colorScheme={p.administrationSigned ? "green" : "yellow"}
                  p="8px"
                >
                  {loading ? (
                    <Spinner />
                  ) : p.administrationSigned ? (
                    "Assinado"
                  ) : (
                    "Pendente"
                  )}
                </Badge>
                {p.administrationSigned && (
                  <>
                    <Box mt="12px" fontSize="12px">
                      {" "}
                      em:{" "}
                      {DateTime.fromISO(
                        p.administrationSignedDate ?? ""
                      ).toFormat("dd/LL/yyyy HH:mm")}
                    </Box>
                    <Box fontSize="12px" mt="4px">
                      {" "}
                      por: {p.administrationSignedBy}
                    </Box>
                  </>
                )}
              </Box>

              <Box>
                <Text fontSize="12px">Comercial:</Text>
                <Badge
                  onClick={() =>
                    (auth.position === 2 || auth.position === 4) &&
                    handleSign("Commercial")
                  }
                  cursor="pointer"
                  colorScheme={p.commercialSigned ? "green" : "yellow"}
                  p="8px"
                >
                  {loading ? (
                    <Spinner />
                  ) : p.commercialSigned ? (
                    "Assinado"
                  ) : (
                    "Pendente"
                  )}
                </Badge>
                {p.commercialSigned && (
                  <>
                    <Box mt="12px" fontSize="12px">
                      {" "}
                      em:{" "}
                      {DateTime.fromISO(p.commercialSignedDate ?? "").toFormat(
                        "dd/LL/yyyy HH:mm"
                      )}
                    </Box>
                    <Box fontSize="12px" mt="4px">
                      {" "}
                      por: {p.commercialSignedBy}
                    </Box>
                  </>
                )}
              </Box>

              <Box>
                <Text fontSize="12px">Financeiro:</Text>
                <Badge
                  onClick={() =>
                    (auth.position === 2 || auth.position === 5) &&
                    handleSign("Financial")
                  }
                  cursor="pointer"
                  colorScheme={p.financialSigned ? "green" : "yellow"}
                  p="8px"
                >
                  {loading ? (
                    <Spinner />
                  ) : p.financialSigned ? (
                    "Assinado"
                  ) : (
                    "Pendente"
                  )}
                </Badge>
                {p.financialSigned && (
                  <>
                    <Box mt="12px" fontSize="12px">
                      {" "}
                      em:{" "}
                      {DateTime.fromISO(p.financialSignedDate ?? "").toFormat(
                        "dd/LL/yyyy HH:mm"
                      )}
                    </Box>
                    <Box fontSize="12px" mt="4px">
                      {" "}
                      por: {p.financialSignedBy}
                    </Box>
                  </>
                )}
              </Box>

              <Box>
                <Text fontSize="12px">Operacional:</Text>
                <Badge
                  onClick={() =>
                    (auth.position === 2 || auth.position === 3) &&
                    handleSign("Operational")
                  }
                  cursor="pointer"
                  colorScheme={p.operationalSigned ? "green" : "yellow"}
                  p="8px"
                >
                  {loading ? (
                    <Spinner />
                  ) : p.operationalSigned ? (
                    "Assinado"
                  ) : (
                    "Pendente"
                  )}
                </Badge>
                {p.operationalSigned && (
                  <>
                    <Box mt="12px" fontSize="12px">
                      {" "}
                      em:{" "}
                      {DateTime.fromISO(p.operationalSignedDate ?? "").toFormat(
                        "dd/LL/yyyy HH:mm"
                      )}
                    </Box>
                    <Box fontSize="12px" mt="4px">
                      {" "}
                      por: {p.operationalSignedBy}
                    </Box>
                  </>
                )}
              </Box>
            </Flex>
          </PopoverContent>
        </Popover>
      </Flex>
      <Flex
        alignItems="center"
        gap="16px"
        cursor="pointer"
        onClick={() => onOpenDetail()}
      >
        <span className="material-symbols-outlined">article</span>
      </Flex>
      <PurchaseOrderModal
        get={get}
        p={p}
        isOpen={isOpenDetail}
        onClose={onCloseDetail}
      />
    </Flex>
  );
};
export default PurchaseOrderCard;
