export const options = [
  {
    title: "Dashboard",
    link: "/dashboard",
    icon: "pie_chart",
  },
  {
    title: "Documentos",
    link: "/documents",
    icon: "description",
    position: "center",
  },
  {
    title: "Processos",
    link: "/processes",
    icon: "inventory_2",
    position: "right",
  },
  {
    title: "Financeiro",
    link: "/financial",
    icon: "account_balance",
    position: "right",
  },
  {
    title: "Ordens de compra",
    link: "/purchaseOrders",
    icon: "request_quote",
    position: "right",
  },
];
