import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  useDisclosure,
  Button,
  IconButton,
  ButtonGroup,
} from "@chakra-ui/react";
import TableWithPagination from "../../components/TableWithPagination";
import Header from "../../components/Header/Header";
import { downloadFile, getProcesses } from "../../services/processService";
import { Tag } from "../../models/Filter";
import Filter from "../../components/Filter";
import { useMediaQuery } from "react-responsive";
import ProcessCard from "./components/ProcessCard";
import FilterModal from "../../components/FilterModal";
import { useNavigate } from "react-router-dom";
import {
  FaColumns,
  FaEye,
  FaFileExcel,
  FaPencilAlt,
  FaPlus,
  FaRegEye,
} from "react-icons/fa";
import ColumnsModal from "./save/components/columnsModal";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { columns } from "./save/components/columns";
import { DateTime } from "luxon";
import SearchableSelect from "../../components/SearchableSelect";
import { getCompanies } from "../../services/companyService";
import { useCompany } from "../../contexts/CompanyContext";
import { CompanyFormData } from "../Configurations/tabs/types";

const Processes = () => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((c) => c.index)
  );
  const [clientView, setClientView] = useState(true);
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState<string>();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [tags, setTags] = useState<Tag[]>([]);
  const [page, setPage] = useState<any[]>([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [clients, setClients] = useState<CompanyFormData[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenColumns,
    onOpen: onOpenColumns,
    onClose: onCloseColumns,
  } = useDisclosure();
  const navigate = useNavigate();
  const auth = useAuthUser<any>();
  useEffect(() => {
    if (auth.position > 1) {
      setClientView(false);
    }
  }, [auth]);

  useEffect(() => {
    const getClients = async () => {
      const response = await getCompanies(false);
      if (response) setClients(response);
    };
    getClients();
  }, []);
  const { currentCompany } = useCompany();
  const get = async (
    localPageSize?: number,
    localPageNumber?: number,
    sortField?: string,
    sortDirection?: string
  ) => {
    setLoading(true);
    const localPs = localPageSize ?? pageSize;
    const localPn = localPageNumber ?? pageIndex;

    const response = await getProcesses({
      pageSize: localPs,
      pageNumber: localPn,
      getAll: !clientView,
      sortField,
      sortDirection,
      type: currentCompany,
      ncm: tags
        .filter((t) => t.type === "ncm")
        ?.map((t) => t.value)
        .join("|"),
      departurePort: tags
        .filter((t) => t.type === "departurePort")
        ?.map((t) => t.value)
        .join("|"),
      cvfRef: tags
        .filter((t) => t.type === "cvfRef")
        ?.map((t) => t.value)
        .join("|"),
      dischargePort: tags
        .filter((t) => t.type === "dischargePort")
        ?.map((t) => t.value)
        .join("|"),
      companyId: client,
      countryOfOrigin: tags
        .filter((t) => t.type === "origin")
        ?.map((t) => t.value)
        .join("|"),
      exporter: tags
        .filter((t) => t.type === "supplier")
        ?.map((t) => t.value)
        .join("|"),
    });
    setPage(response.data);
    setTimeout(() => {
      setLoading(false);
    }, 200);
    return response;
  };
  useEffect(() => {
    get();
  }, [tags, clientView, client, currentCompany]);

  const download = async () => {
    setLoadingFile(true);
    var file = await downloadFile({
      columns: selectedColumns,
      ncm: tags
        .filter((t) => t.type === "ncm")
        ?.map((t) => t.value)
        .join("|"),
      departurePort: tags
        .filter((t) => t.type === "departurePort")
        ?.map((t) => t.value)
        .join("|"),
      cvfRef: tags
        .filter((t) => t.type === "cvfRef")
        ?.map((t) => t.value)
        .join("|"),
      dischargePort: tags
        .filter((t) => t.type === "dischargePort")
        ?.map((t) => t.value)
        .join("|"),
      companyId: client,
      countryOfOrigin: tags
        .filter((t) => t.type === "origin")
        ?.map((t) => t.value)
        .join("|"),
      exporter: tags
        .filter((t) => t.type === "supplier")
        ?.map((t) => t.value)
        .join("|"),
    });
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = `Follow-Up ${DateTime.now().toFormat(
      "dd-LL-yyyy"
    )}`;
    downloadLink.click();
    setLoadingFile(false);
  };

  return (
    <Box height="100%" overflowX="hidden">
      <Header />
      <Flex
        height="100%"
        flexDirection="column"
        alignItems="center"
        backgroundColor="var(--main-bg-color)"
      >
        <Flex width="90%">
          <Heading size="lg" mt="48px" mb="24px" fontWeight="normal">
            Processos
          </Heading>
        </Flex>
        <FilterModal
          {...{
            isOpen,
            onClose,
            tags,
            setTags,
          }}
        />
        <Box
          p="32px"
          w="90%"
          backgroundColor="var(--filter-color)"
          borderRadius="8px"
        >
          {isMobile ? (
            <Flex w="100%" justifyContent="center">
              <Button
                w="80%"
                backgroundColor="var(--icon-color)"
                color="white"
                onClick={onOpen}
              >
                Filtrar documentos
              </Button>
            </Flex>
          ) : (
            <Flex width="90%">
              <Filter
                extraFilters={
                  auth.position > 1
                    ? [
                        <Box>
                          <Heading size="sm" mb="8px">
                            Clientes
                          </Heading>
                          <SearchableSelect
                            backgroundColor="white"
                            options={[
                              {
                                value: undefined,
                                label: "Emulada",
                              },
                              ...clients.map((c) => ({
                                value: c.id,
                                label: c.name,
                                subLabel: c.cnpj,
                              })),
                            ]}
                            name="clientId"
                            setValue={(value) => {
                              setClient(value);
                            }}
                          />
                        </Box>,
                      ]
                    : []
                }
                {...{
                  tags,
                  setTags,
                }}
              />
            </Flex>
          )}
        </Box>
        {auth.position > 1 && (
          <Flex w="90%" mt="32px" justifyContent="flex-end" gap="16px">
            <Button
              leftIcon={clientView ? <FaRegEye /> : <FaEye />}
              onClick={() => setClientView((cv) => !cv)}
            >
              {clientView ? "Visão de tabela" : "Visão do cliente"}
            </Button>
            <Button
              color="white"
              borderRadius="4px"
              backgroundColor="#1C6C3F"
              onClick={() => download()}
              isLoading={loadingFile}
            >
              <Flex justifyContent="center" alignItems="center" gap="8px">
                <FaFileExcel color="white" /> Gerar Follow-up
              </Flex>
            </Button>
            <Button
              color="white"
              borderRadius="4px"
              backgroundColor="var(--icon-color)"
              onClick={() => onOpenColumns()}
              isLoading={loadingFile}
            >
              <Flex justifyContent="center" alignItems="center" gap="8px">
                <FaColumns /> Personalizar colunas
              </Flex>
            </Button>
            <ColumnsModal
              {...{ selectedColumns, setSelectedColumns }}
              isOpen={isOpenColumns}
              onClose={onCloseColumns}
              tags={tags}
            />
            <Button
              color="white"
              borderRadius="4px"
              backgroundColor="var(--icon-color)"
              onClick={() => navigate("/saveProcess")}
            >
              <Flex justifyContent="center" alignItems="center" gap="8px">
                <FaPlus color="white" /> Adicionar Processo
              </Flex>
            </Button>
          </Flex>
        )}
        {auth.position === 0 || clientView ? (
          <>
            <Flex width="90%" flexDir="column" mt="64px" gap="24px">
              {page.map((p) => (
                <ProcessCard {...{ p }} />
              ))}
            </Flex>
            <Flex width="90%" my="32px">
              <TableWithPagination
                hideTable
                columns={[]}
                {...{
                  tags,
                  page,
                  setPage,
                  get,
                  pageIndex,
                  setPageIndex,
                  pageSize,
                  setPageSize,
                }}
                emptyMessage="Não há processos ainda"
              />
            </Flex>
          </>
        ) : (
          <Flex
            flexDir="column"
            width="90%"
            my="32px"
            p="32px"
            backgroundColor="white"
          >
            <TableWithPagination
              loading={loading}
              columns={[
                ...columns.filter((c) =>
                  selectedColumns.some((sc) => sc === c.index)
                ),
                {
                  label: "",
                  data: (p: any) => (
                    <ButtonGroup>
                      <a href={`/saveProcess?id=${p?.id}`}>
                        <IconButton
                          backgroundColor="var(--icon-color)"
                          color="white"
                          aria-label="Editar"
                          icon={<FaPencilAlt />}
                        />
                      </a>
                      <a href={`/process?id=${p.id}`}>
                        <IconButton
                          backgroundColor="var(--icon-color)"
                          color="white"
                          aria-label="Ver detalhes"
                          icon={<FaEye />}
                        />
                      </a>
                    </ButtonGroup>
                  ),
                },
              ]}
              {...{
                tags,
                page,
                setPage,
                get,
                pageIndex,
                setPageIndex,
                pageSize,
                setPageSize,
              }}
              emptyMessage="Não há processos ainda"
            />
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default Processes;
