import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCheckCircle,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { generateSignUpToken } from "../../../services/userService";
import { useNavigate } from "react-router-dom";
import { getCompanies } from "../../../services/companyService";
import { useMediaQuery } from "react-responsive";
import { FaChevronDown, FaPlus, FaUserPlus } from "react-icons/fa";
import { positions } from "../../../models/PurchaseOrder";
import SearchableSelect from "../../../components/SearchableSelect";
import { GenerateSignUpTokenCommand } from "../../../models/User";

const AddUsers = () => {
  const navigate = useNavigate();
  const [emails, setEmails] = useState<GenerateSignUpTokenCommand[]>([]);
  const [company, setCompany] = useState<any>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [position, setPosition] = useState(0);
  const [error, setError] = useState<string>();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const sendEmails = async () => {
    setLoading(true);
    const response = await generateSignUpToken(
      emails.map((e) => ({
        ...e,
        companyId: company,
      }))
    );
    if (response) {
      onOpen();
    }
    setLoading(false);
  };
  const getCompaniesOptions = async () => {
    const response = await getCompanies();
    if (response) setCompanies(response);
  };
  useEffect(() => {
    getCompaniesOptions();
  }, []);
  return (
    <Box>
      <Heading size="lg" mt="48px" mb="24px" fontWeight="normal">
        <Flex alignItems="center" gap="12px">
          <FaUserPlus color="var(--icon-color)" /> Adicionar usuários
        </Flex>
      </Heading>
      <Box width="100%" backgroundColor="white" borderRadius="8px" p="24px">
        <Heading size="sm" fontWeight="normal">
          E-mail(s)
        </Heading>
        <Flex flexDirection="column">
          <ButtonGroup>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            <Select
              value={position}
              onChange={(e) => setPosition(parseFloat(e.target.value))}
            >
              {positions.map((p) => (
                <option key={p.key} value={p.key}>
                  {p.label}
                </option>
              ))}
            </Select>
            <Checkbox
              isChecked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
            >
              Admin
            </Checkbox>

            <IconButton
              onClick={() => {
                const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const valid = regexEmail.test(email ?? "");
                if (!valid) {
                  setError("E-mail inválido.");
                  return;
                }
                if (emails.some((e) => e.email === email)) {
                  setError("E-mail já adicionado.");
                  return;
                }
                setError("");
                setEmail("");
                setEmails((emails) => [
                  ...emails,
                  {
                    email: email ?? "",
                    admin: isAdmin,
                    position,
                    companyId: "",
                  },
                ]);
              }}
              color="white"
              backgroundColor="var(--icon-color)"
              aria-label="add"
              icon={<FaPlus />}
            />
          </ButtonGroup>
          <Flex flexWrap={"wrap"} marginY="8px" gap="16px">
            {emails.map((e) => (
              <Flex
                backgroundColor="var(--icon-color)"
                borderColor="#5A96E3"
                borderRadius="3px"
                borderWidth="1px"
                gap="8px"
                height="32px"
                padding="4px 8px"
                color="white"
                alignItems="center"
              >
                <Text fontWeight="semibold">
                  {e.email} |{" "}
                  {positions.find((p) => p.key === e.position)?.label}
                  {e.admin ? " | Admin" : ""}
                </Text>
                <FontAwesomeIcon
                  icon={faClose}
                  color="white"
                  onClick={() =>
                    setEmails((emails) => {
                      const newEmails = emails.map((t) => t);
                      newEmails.splice(
                        emails.findIndex((t) => t.email === e.email),
                        1
                      );
                      return newEmails;
                    })
                  }
                />
              </Flex>
            ))}
          </Flex>
          {error && (
            <Text marginTop="16px" color="red">
              {error}
            </Text>
          )}
          <Heading size="sm" fontWeight="normal" mt="24px" marginBottom="8px">
            Empresa
          </Heading>

          <SearchableSelect
            options={companies.map((c) => ({ value: c.id, label: c.name }))}
            value={company}
            setValue={(value) => setCompany(value)}
          />
        </Flex>
        <Modal size="xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Flex
                alignItems="center"
                flexDirection="column"
                padding="64px"
                gap="64px"
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="green"
                  fontSize="120px"
                />
                <Heading textAlign="center">
                  E-mail(s) enviado(s) com sucesso!
                </Heading>
                <ButtonGroup>
                  <Button
                    backgroundColor="#29234b"
                    color="white"
                    onClick={() => navigate("/")}
                  >
                    Voltar para o Início
                  </Button>
                  <Button onClick={onClose}>Enviar mais e-mails</Button>
                </ButtonGroup>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
      <Flex w="100%" justifyContent="flex-end">
        <Button
          marginY="32px"
          backgroundColor="var(--icon-color)"
          color="white"
          onClick={() =>
            error?.length === 0 && emails.length > 0 && sendEmails()
          }
          isLoading={loading}
        >
          Enviar e-mails
        </Button>
      </Flex>
    </Box>
  );
};

export default AddUsers;
