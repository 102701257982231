import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import DocumentUpload from "../../../../components/DocumentUpload";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FaFileExcel, FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import {
  deleteCompany,
  getCompanies,
  importCompanies,
  saveCompany,
} from "../../../../services/companyService";
import { cnpjString } from "../../../../services/utils";
import { TableHead } from "@mui/material";
import ImportModal from "./components/ImportModal";
import CompanyForm from "./components/CompanyForm";
import { CompanyFormData } from "../types";

const CompaniesList = () => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [loading, setLoading] = useState(false);
  const [add, setAdd] = useState(false);
  const [update, setUpdate] = useState<CompanyFormData>();
  const [companies, setCompanies] = useState<CompanyFormData[]>([]);
  const [filteredCompanies, setFilteredCompanies] =
    useState<CompanyFormData[]>(companies);
  const [error, setError] = useState<{ nome?: string; cnpj: string }>();

  const remove = async (id: string) => {
    setLoading(true);
    await deleteCompany(id);
    await getSavedCompanies();
    toast({
      title: "Cliente removido.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    setLoading(false);
  };
  const getSavedCompanies = async () => {
    const response = await getCompanies(false);
    if (response) {
      setCompanies(response);
      setFilteredCompanies(response);
    }
  };
  useEffect(() => {
    getSavedCompanies();
  }, []);
  return (
    <Box>
      {add ? (
        <CompanyForm get={getSavedCompanies} set={setAdd} />
      ) : update ? (
        <CompanyForm get={getSavedCompanies} set={setUpdate} id={update.id} />
      ) : (
        <Flex flexDir="column" overflowY="auto" p="24px">
          <ImportModal {...{ isOpen, onClose, getSavedCompanies }} />
          <Flex justifyContent="space-between" alignItems="center" w="100%">
            <Heading size="lg" fontWeight="normal">
              <Flex alignItems="center" gap="12px">
                <FontAwesomeIcon
                  color="var(--icon-color)"
                  icon={faHandshake}
                  fontSize={isMobile ? 18 : 24}
                />
                Gerencinar clientes
              </Flex>
            </Heading>
            <Flex justifyContent="flex-end" alignItems="center" gap="16px">
              <Button
                color="white"
                borderRadius="4px"
                backgroundColor="#1C6C3F"
                onClick={() => onOpen()}
              >
                <Flex justifyContent="center" alignItems="center" gap="8px">
                  <FaFileExcel color="white" /> Importar
                </Flex>
              </Button>
              <Button
                color="white"
                borderRadius="4px"
                backgroundColor="var(--icon-color)"
                onClick={() => setAdd(true)}
              >
                <Flex justifyContent="center" alignItems="center" gap="8px">
                  <FaPlus color="white" /> Adicionar
                </Flex>
              </Button>
            </Flex>
          </Flex>
          <Input
            placeholder="Pesquise um cliente"
            my="32px"
            height="40px !important"
            onChange={(e) =>
              setFilteredCompanies((fs) => {
                const newfs = [...companies];
                return newfs.filter((f) =>
                  f.name.toLowerCase().includes(e.target.value.toLowerCase())
                );
              })
            }
          />
          <Flex flexDir="column" gap="16px" h="400px" overflowY="auto">
            <Table>
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>CNPJ</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredCompanies.map((p) => (
                  <Tr>
                    <Td>
                      <Heading size="sm" fontWeight="normal">
                        {p.name}
                      </Heading>
                    </Td>
                    <Td>
                      <Heading size="sm" fontWeight="normal">
                        {p.cnpj}
                      </Heading>
                    </Td>
                    <Td>
                      <FaPencilAlt
                        onClick={() => {
                          setUpdate(p);
                          setAdd(false);
                        }}
                        cursor="pointer"
                      />
                    </Td>
                    <Td>
                      <FaTrash
                        onClick={() => remove(p.id ?? "")}
                        cursor="pointer"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default CompaniesList;
