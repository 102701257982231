import { DateTime } from "luxon";
import { ProcessViewModel } from "../../../../models/Process";

export const columns = [
  {
    label: "CVF Ref",
    sortable: true,
    data: (p: ProcessViewModel) => p.cvfRef,
    index: "cvfRef",
  },
  {
    label: "Número Ref.",
    sortable: true,
    data: (p: ProcessViewModel) => p.clientRef,
    index: "clientRef",
  },
  {
    label: "Cliente",
    sortable: true,
    data: (p: ProcessViewModel) => p.clientName,
    index: "clientName",
  },
  {
    label: "Aparece pro cliente",
    sortable: true,
    data: (p: ProcessViewModel) => p.refName,
    index: "clientName",
  },
  {
    label: "Despachante",
    sortable: true,
    data: (p: ProcessViewModel) => p.dispatcher,
    index: "dispatcher",
  },
  {
    label: "Status",
    sortable: true,
    data: (p: ProcessViewModel) => p.status,
    index: "status",
  },
  {
    label: "Incoterm",
    sortable: true,
    data: (p: ProcessViewModel) => p.incoterm,
    index: "incoterm",
  },
  {
    label: "Modo de Importação",
    sortable: true,
    data: (p: ProcessViewModel) => p.importMode,
    index: "importMode",
  },
  {
    label: "Exportador",
    sortable: true,
    data: (p: ProcessViewModel) => p.exporter,
    index: "exporter",
  },
  {
    label: "Fornecedor",
    sortable: true,
    data: (p: ProcessViewModel) => p.exporter,
    index: "exporter",
  },
  {
    label: "Total Invoice",
    data: (p: ProcessViewModel) => p.totalInvoice,
    index: "totalInvoice",
  },
  {
    label: "CE Mercante",
    sortable: true,
    data: (p: ProcessViewModel) => p.ceMerchant,
    index: "condition",
  },
  {
    label: "Forma de pagamento",
    sortable: true,
    data: (p: ProcessViewModel) => p.condition,
    index: "condition",
  },
  {
    label: "Fatura",
    sortable: true,
    data: (p: ProcessViewModel) => p.invoice,
    index: "invoice",
  },
  {
    label: "LI",
    sortable: true,
    data: (p: ProcessViewModel) => p.li,
    index: "li",
  },
  {
    label: "Validade",
    sortable: true,
    data: (p: ProcessViewModel) => p.validity,
    index: "validity",
  },
  {
    label: "BL/AWB",
    sortable: true,
    data: (p: ProcessViewModel) => p.blAwbCrt,
    index: "blAwbCrt",
  },
  {
    label: "Data Embarque",
    sortable: true,
    data: (p: ProcessViewModel) =>
      p.shippingDate
        ? DateTime.fromISO(p.shippingDate).toFormat("dd/LL/yyyy")
        : "",
    index: "shippingDate",
  },
  {
    label: "Data Chegada",
    sortable: true,
    data: (p: ProcessViewModel) =>
      p.arrivalDate
        ? DateTime.fromISO(p.arrivalDate).toFormat("dd/LL/yyyy")
        : "",
    index: "arrivalDate",
  },
  {
    label: "Nº Container",
    sortable: true,
    data: (p: ProcessViewModel) => p.containerNumber,
    index: "containerNumber",
  },
  {
    label: "Embarcação",
    sortable: true,
    data: (p: ProcessViewModel) => p.vessel,
    index: "vessel",
  },
  {
    label: "Porto Descarga",
    sortable: true,
    data: (p: ProcessViewModel) => p.dischargePort,
    index: "dischargePort",
  },
  {
    label: "Canal",
    sortable: true,
    data: (p: ProcessViewModel) => p.channel,
    index: "channel",
  },
  {
    label: "DI",
    sortable: true,
    data: (p: ProcessViewModel) => p.di,
    index: "di",
  },
  {
    label: "Data Registro",
    sortable: true,
    data: (p: ProcessViewModel) =>
      p.registrationDate
        ? DateTime.fromISO(p.registrationDate).toFormat("dd/LL/yyyy")
        : "",
    index: "registrationDate",
  },
  {
    label: "Data do Desembaraço",
    sortable: true,
    data: (p: ProcessViewModel) =>
      p.customsClearanceDate
        ? DateTime.fromISO(p.customsClearanceDate).toFormat("dd/LL/yyyy")
        : "",
    index: "customsClearanceDate",
  },
  {
    label: "Agente de Cargas",
    sortable: true,
    data: (p: ProcessViewModel) => p.carrier,
    index: "carrier",
  },
  {
    label: "Origem",
    sortable: true,
    data: (p: ProcessViewModel) => p.countryOfOrigin,
    index: "countryOfOrigin",
  },
  {
    label: "Transportador",
    sortable: true,
    data: (p: ProcessViewModel) => p.carrier,
    index: "carrier",
  },
  {
    label: "Demurrage",
    sortable: true,
    data: (p: ProcessViewModel) => p.demurrage,
    index: "demurrage",
  },
  {
    label: "Data Emissão da NF",
    sortable: true,
    data: (p: ProcessViewModel) => p.nfIssued,
    index: "nfIssued",
  },
  {
    label: "NCM",
    sortable: true,
    data: (p: ProcessViewModel) => p.ncm,
    index: "ncm",
  },
];
